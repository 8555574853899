/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** @format int32 */
export enum Access {
	View = 1,
	Create = 2,
	Edit = 4,
	Delete = 8,
	Invite = 16,
	Approve = 32,
	Decline = 64,
	Upload = 128,
	Attach = 256,
	Detach = 512,
	Download = 1024,
	Request = 2048,
}

/** @format int32 */
export enum ActionType {
	ContactDelete = 0,
	ProjectReopen = 1,
}

export interface AdministratorInfo {
	id?: string | null;
	userName?: string | null;
	email?: string | null;
}

export interface AppliedScopeInfo {
	projectId?: string | null;
	name?: string | null;
	/** @format date-time */
	dateTime?: string;
}

export interface ApprovalAuthor {
	authorId?: string | null;
	authorName?: string | null;
	authorType?: UserType;
	role?: Roles;
	authorProjectRole?: Roles;
	authorProjectSubRole?: ProjectSubRoles;
	/** @format date-time */
	approvalDate?: string | null;
}

export interface ApproveUserByInvitationModel {
	/** @minLength 1 */
	messageId: string;
	isApproved: boolean;
}

/** @format int32 */
export enum ApproverDecision {
	Pending = 0,
	Approved = 1,
	Declined = 2,
}

/** @format int32 */
export enum AssessmentDocumentType {
	ReportOnCompliance = 0,
	AttestationOfComplianceMerchant = 1,
	AttestationOfComplianceServiceProviders = 2,
	RemoteAssessmentAddendum = 3,
	SupplementalAttestationOfCompliance = 4,
	ItemsNotedForImprovement = 5,
	InitialAuthorizationPackageChecklist = 6,
	FedRampAtoLetter = 7,
	LowReadinessAssessmentReport = 8,
	ModerateReadinessAssessmentReport = 9,
	HighReadinessAssessmentReport = 10,
	SystemSecurityPlan = 11,
	SystemSecurityPlanAppendixALow = 12,
	SystemSecurityPlanAppendixAModerate = 13,
	SystemSecurityPlanAppendixAHigh = 14,
	SystemSecurityPlanAppendixALiSaas = 15,
	SystemSecurityPlanAppendixF = 16,
	SystemSecurityPlanAppendixG = 17,
	SystemSecurityPlanAppendixJ = 18,
	SystemSecurityPlanAppendixM = 19,
	SystemSecurityPlanAppendixO = 20,
	SystemSecurityPlanAppendixQ = 21,
	SecurityAssessmentPlan = 22,
	SecurityAssessmentPlanAppendixAHigh = 23,
	SecurityAssessmentPlanAppendixAModerate = 24,
	SecurityAssessmentPlanAppendixALow = 25,
	SecurityAssessmentReport = 26,
	SecurityAssessmentReportAppendixA = 27,
	SecurityAssessmentReportAppendixB = 28,
	WorkPapersExcel = 29,
	WorkPapersArchive = 30,
	RemediationList = 31,
	EmptyDocument = 32,
	StatusReport = 33,
	Scope = 34,
}

export interface AttachmentBaseModel {
	id?: string | null;
	description?: string | null;
	type?: RocDataType;
	/** @minLength 1 */
	projectId: string;
	referenceNumber?: string | null;
	referenceItems?: NumberingSearch[] | null;
}

export interface AttachmentBaseModelIEnumerableSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: AttachmentBaseModel[] | null;
}

export interface AttachmentLink {
	id?: string | null;
	rocDataType?: RocDataType;
	/** @format date-time */
	editTime?: string | null;
	createdBy?: EntryAuthor;
	modifiedBy?: EntryAuthor;
	paymentChannelId?: string | null;
	requestId?: string | null;
	disclosureSummary?: string | null;
	appliedScopeInfo?: AppliedScopeInfo;
	attachmentId?: string | null;
	evidenceLocation?: string | null;
	approvalAuthor?: ApprovalAuthor;
}

export interface AuditAction {
	controllerName?: string | null;
	actionName?: string | null;
	actionArguments?: Record<string, string>;
	impersonationId?: string | null;
}

export interface AuditLogListResult {
	list?: AuditLogModel[] | null;
	users?: UserInfo[] | null;
	contacts?: ContactModel[] | null;
}

export interface AuditLogListResultSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: AuditLogListResult;
}

export interface AuditLogModel {
	/**
	 * @minLength 1
	 * @maxLength 36
	 */
	id: string;
	/**
	 * @minLength 1
	 * @maxLength 36
	 */
	projectId: string;
	/** @maxLength 36 */
	itemId?: string | null;
	/** @maxLength 36 */
	paymentChannelId?: string | null;
	/** @maxLength 36 */
	replyToWorkflowId?: string | null;
	untracked?: boolean;
	/** @format date-time */
	createdDate?: string;
	/** @format date-time */
	modifiedDate?: string;
	createdBy?: EntryAuthor;
	/** @maxLength 36 */
	linkedDataId?: string | null;
	linkedDataType?: Resource;
	message?: string | null;
	systemMessage?: string | null;
	status?: ItemStatus;
	forced?: boolean;
	rocDataType?: RocDataType;
	notApplicable?: boolean;
	resource?: Resource;
	access?: Access;
	rocDataInfo?: RocDataInfo[] | null;
	attachments?: AttachmentLink[] | null;
	visibleToRoles?: Roles;
	request?: Request;
	isAuto?: boolean;
	isAutofillLog?: boolean;
	isHidden?: boolean;
	isWorkPaper?: boolean;
	/** @format int32 */
	currentProjectProgress?: number;
	auditAction?: AuditAction;
	projectName?: string | null;
	itemNumber?: string | null;
	chapterId?: string | null;
}

export interface AuditReportSettingsModel {
	/** @maxLength 36 */
	id?: string | null;
	/** @maxLength 36 */
	userId?: string | null;
	/**
	 * @minLength 1
	 * @maxLength 36
	 */
	projectId: string;
	mailingDays?: DaysOfWeek;
	time?: string | null;
	customRecipients?: string | null;
	customOnly?: boolean;
}

export interface AuditReportSettingsModelArraySuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: AuditReportSettingsModel[] | null;
}

export interface Brand {
	title?: string | null;
	description?: string | null;
	logoUrl?: string | null;
	emailLogoPngUrl?: string | null;
	horizontalLogoUrl?: string | null;
	backgroundUrl?: string | null;
	backgroundSizePx?: string | null;
	manifestUrl?: string | null;
	faviconUrl?: string | null;
	reportHeaderUrl?: string | null;
	reportCoverPageUrl?: string | null;
	colors?: Colors;
	filesConfig?: Record<string, BrandAllowedMimeTypes>;
}

/** @format int32 */
export enum BrandAllowedMimeTypes {
	Svg = 1,
	Png = 2,
	Json = 4,
	Ico = 8,
	Docx = 16,
	XIcon = 32,
}

/** @format int32 */
export enum BrandFileType {
	Logo = 1,
	EmailLogoPng = 2,
	HorizontalLogo = 3,
	Background = 4,
	Manifest = 5,
	Favicon = 6,
	ReportHeaderLogo = 7,
	ReportCoverPage = 8,
}

export interface Breadcrumb {
	itemId?: string | null;
	name?: string | null;
	numbering?: string | null;
}

/** @format int32 */
export enum CentralFileSystemType {
	OsFileSystem = 0,
	S3FileSystem = 1,
}

export interface ChangePasswordModel {
	/**
	 * @format password
	 * @minLength 1
	 */
	oldPassword: string;
	/**
	 * @format password
	 * @minLength 6
	 * @maxLength 100
	 */
	newPassword: string;
}

export interface ChangeUserPasswordModel {
	/**
	 * @format password
	 * @minLength 6
	 * @maxLength 100
	 */
	newPassword: string;
	userId?: string | null;
}

export interface Channel {
	id?: string | null;
	/** @minLength 1 */
	name: string;
	description?: string | null;
	assessorNotes?: string | null;
	type?: ChannelType;
	subType?: ChannelSubType;
	modelType?: ModelType;
	saq?: SAQEntry;
	docxCaption?: string | null;
}

export interface ChannelArraySuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: Channel[] | null;
}

/** @format int32 */
export enum ChannelSubType {
	Other = 0,
	HostingProvider = 1,
	ManagedServices = 2,
	PaymentProcessing = 3,
}

/** @format int32 */
export enum ChannelType {
	Payment = 1,
	Service = 2,
	CardPresent = 4,
}

export interface ClientAppLog {
	/** @minLength 1 */
	message: string;
	fileLocation?: string | null;
	line?: string | null;
	col?: string | null;
	link?: string | null;
}

export interface ClientModel {
	id?: string | null;
	/** @minLength 1 */
	name: string;
	companyId?: string | null;
	createdById?: string | null;
	dba?: string | null;
	/** @format int32 */
	statusId?: number | null;
	address?: string | null;
	contactName?: string | null;
	contactTitle?: string | null;
	contactPhone?: string | null;
	contactEmail?: string | null;
	siteUrl?: string | null;
	/** @format date-time */
	createdDate?: string | null;
	/** @format date-time */
	modifiedDate?: string | null;
	allocatedResponsibilities?: ResponsibilityArea;
}

export interface ClientModelArraySuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: ClientModel[] | null;
}

export interface Colors {
	mainLight?: string | null;
	mainDark?: string | null;
	secondary?: string | null;
	success?: string | null;
	highlight?: string | null;
	warning?: string | null;
	error?: string | null;
}

export interface CommandLineAppResponse {
	success?: boolean;
	/** @format int32 */
	exitCode?: number;
	stdOut?: string | null;
	stdErr?: string | null;
}

export interface CommandLineAppResponseSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: CommandLineAppResponse;
}

export interface CompanyInfo {
	id?: string | null;
	companyName?: string | null;
	dbaName?: string | null;
	phoneNumber?: string | null;
	billingAddress?: string | null;
	siteUrl?: string | null;
	email?: string | null;
	evidenceRetentionMessage?: string | null;
	administrators?: AdministratorInfo[] | null;
}

export interface CompanyInfoArraySuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: CompanyInfo[] | null;
}

export interface CompanyModel {
	id?: string | null;
	/** @minLength 1 */
	companyName: string;
	dbaName?: string | null;
	/**
	 * @format tel
	 * @minLength 1
	 */
	phoneNumber: string;
	/** @minLength 1 */
	billingAddress: string;
	siteUrl?: string | null;
	createdById?: string | null;
	/** @format int32 */
	statusId?: number | null;
	/** @format date-time */
	createdDate?: string | null;
	/** @format date-time */
	modifiedDate?: string | null;
	/** @format email */
	email?: string | null;
	evidenceRetentionMessage?: string | null;
	isCreatedByCompanyAdmin?: boolean;
}

export interface CompanyModelArraySuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: CompanyModel[] | null;
}

export interface CompanyToken {
	/**
	 * @minLength 1
	 * @maxLength 36
	 */
	id: string;
	/**
	 * @minLength 1
	 * @maxLength 36
	 */
	tokenId: string;
	/**
	 * @minLength 1
	 * @maxLength 36
	 */
	senderId: string;
	impersonationId?: string | null;
	/** @format date-time */
	requestDate: string;
	/** @format date-time */
	expirationDate: string;
	/**
	 * @minLength 1
	 * @maxLength 36
	 */
	companyId: string;
	status: TokenStatus;
	type: TokenType;
	/** @minLength 1 */
	token: string;
	resolution?: string | null;
}

export interface CompensatingControlRocItem {
	/** @format int32 */
	level?: number;
	parentsIds?: string[] | null;
	editorParentIds?: string[] | null;
	projectId?: string | null;
	itemId?: string | null;
	parentKey?: string | null;
	id?: RocItemId;
	/** @format int32 */
	order?: number;
	/** @format date-time */
	updateTime?: string;
	modifiedBy?: EntryAuthor;
	isDeleted?: boolean;
	isPermanentDeletionRequested?: boolean;
	untracked?: boolean;
	version?: ReportType;
	nodeType?: NodeType;
	group?: ReportGroup;
	standardHints?: HelpMessage[] | null;
	summaryId?: string | null;
	nonComplianceReasons?: NonComplianceReason[] | null;
	nonCompliant?: boolean;
	notApplicableReasons?: ReasonBase[] | null;
	notApplicable?: boolean;
	parentProgressItemId?: string | null;
	ignoreProgress?: boolean;
	forceProgress?: boolean;
	status?: ItemStatus;
	rocWarnings?: RocWarningType;
	customWarnings?: RocWarningType;
	hiddenWarnings?: RocWarningType;
	modelType?: ModelType;
	/** @deprecated */
	hasAutoEvent?: boolean;
	subType?: string | null;
	readPermissionMask?: Roles;
	writePermissionMask?: Roles;
	variableName?: string | null;
	autoComplete?: ReportAutoComplete;
	viewStyleInformation?: StyleInformation;
	style?: StyleInformation;
	contentsInfo?: RocContentsInfo;
	useDifferentValuesPerPaymentChannels?: boolean | null;
	isHiddenInReport?: boolean;
	responsibilityArea?: ResponsibilityArea;
	linkedItemId?: string | null;
	saq?: Saq;
	isHintsEnabled?: boolean;
	isGuidanceEnabled?: boolean;
	isLookUpDisabled?: boolean;
	isRequiredForEvidences?: boolean;
	isAutoGenerated?: boolean;
	isAutoModified?: boolean;
	/** @format date-time */
	lastAutofillDate?: string | null;
	needsWorkflowOnFill?: boolean;
	itemDescription?: string | null;
	interviewId?: string | null;
	/** @format int32 */
	requirementNumber?: number;
	numberingOrderBy?: string | null;
	excelAttributes?: ExcelAttributes;
	appliedScopeInfo?: AppliedScopeInfo;
	type?: RocItemType;
	rowId?: string | null;
	containerId?: string | null;
	requirementDef?: string | null;
	constraints?: string | null;
	objective?: string | null;
	identifiedRisk?: string | null;
	definition?: string | null;
	validation?: string | null;
	maintenance?: string | null;
	disclosureSummary?: string | null;
}

export interface CompensatingControlRocItemInput {
	constraints?: string | null;
	objective?: string | null;
	identifiedRisk?: string | null;
	definition?: string | null;
	validation?: string | null;
	maintenance?: string | null;
	disclosureSummary?: string | null;
	isIncomplete?: boolean;
}

export interface CompensatingControlRocItemSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: CompensatingControlRocItem;
}

export interface ConfirmAccountNoPasswordModel {
	/**
	 * @format password
	 * @minLength 6
	 * @maxLength 100
	 */
	password: string;
	/** @minLength 1 */
	token: string;
}

export interface ContactModel {
	id?: string | null;
	/** @minLength 1 */
	name: string;
	clientId?: string | null;
	responsibilityArea: ResponsibilityArea;
	status?: ContactStatus;
	jobTitle?: string | null;
	organization?: string | null;
	phone?: string | null;
	/** @format email */
	email?: string | null;
	isISA?: boolean;
	isPrimary?: boolean;
	/** @format int32 */
	index?: number;
	isPendingDelete?: boolean;
	projectId?: string | null;
	itemId?: string | null;
	interviewId?: string | null;
}

export interface ContactModelArraySuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: ContactModel[] | null;
}

export interface ContactModelSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: ContactModel;
}

/** @format int32 */
export enum ContactStatus {
	ContactOnly = 0,
	UserInvitationSent = 1,
	UserAccountCreated = 2,
}

export interface ContainerRocItem {
	/** @format int32 */
	level?: number;
	parentsIds?: string[] | null;
	editorParentIds?: string[] | null;
	projectId?: string | null;
	itemId?: string | null;
	parentKey?: string | null;
	id?: RocItemId;
	/** @format int32 */
	order?: number;
	/** @format date-time */
	updateTime?: string;
	modifiedBy?: EntryAuthor;
	isDeleted?: boolean;
	isPermanentDeletionRequested?: boolean;
	untracked?: boolean;
	version?: ReportType;
	nodeType?: NodeType;
	group?: ReportGroup;
	standardHints?: HelpMessage[] | null;
	summaryId?: string | null;
	nonComplianceReasons?: NonComplianceReason[] | null;
	nonCompliant?: boolean;
	notApplicableReasons?: ReasonBase[] | null;
	notApplicable?: boolean;
	parentProgressItemId?: string | null;
	ignoreProgress?: boolean;
	forceProgress?: boolean;
	status?: ItemStatus;
	rocWarnings?: RocWarningType;
	customWarnings?: RocWarningType;
	hiddenWarnings?: RocWarningType;
	modelType?: ModelType;
	/** @deprecated */
	hasAutoEvent?: boolean;
	subType?: string | null;
	readPermissionMask?: Roles;
	writePermissionMask?: Roles;
	variableName?: string | null;
	autoComplete?: ReportAutoComplete;
	viewStyleInformation?: StyleInformation;
	style?: StyleInformation;
	contentsInfo?: RocContentsInfo;
	useDifferentValuesPerPaymentChannels?: boolean | null;
	isHiddenInReport?: boolean;
	responsibilityArea?: ResponsibilityArea;
	linkedItemId?: string | null;
	saq?: Saq;
	isHintsEnabled?: boolean;
	isGuidanceEnabled?: boolean;
	isLookUpDisabled?: boolean;
	isRequiredForEvidences?: boolean;
	isAutoGenerated?: boolean;
	isAutoModified?: boolean;
	/** @format date-time */
	lastAutofillDate?: string | null;
	needsWorkflowOnFill?: boolean;
	itemDescription?: string | null;
	interviewId?: string | null;
	/** @format int32 */
	requirementNumber?: number;
	numberingOrderBy?: string | null;
	excelAttributes?: ExcelAttributes;
	appliedScopeInfo?: AppliedScopeInfo;
	children?: RocItem[] | null;
	type?: RocItemType;
	containerType?: ContainerRocItemType;
	progress?: Record<string, number | null>;
	rocQa?: Record<string, number | null>;
	header?: RocItem;
	name?: string | null;
	requirementAssignees?: ShortUserInfo[] | null;
	requirementApproach?: RequirementApproach;
}

export interface ContainerRocItemIEnumerableSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: ContainerRocItem[] | null;
}

export interface ContainerRocItemListSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: ContainerRocItem[] | null;
}

/** @format int32 */
export enum ContainerRocItemType {
	Block = 0,
	Table = 1,
	Empty = 2,
	FedRampControl = 3,
	Row = 4,
}

/** @format int32 */
export enum ContentsPageNumberType {
	Roman = 0,
	Arabic = 1,
}

export interface ControlStrength {
	reference?: string | null;
	description?: string | null;
	controlCategory?: string | null;
	details?: DetailBase[] | null;
	reduction?: ControlStrengthReduction;
	/** @format double */
	overallControlStrength?: number;
}

/** @format int32 */
export enum ControlStrengthReduction {
	DetectProtectContain = 0,
	RecoverRemediateRestore = 1,
}

export interface ControlsMatrixInput {
	/**
	 * @minLength 1
	 * @maxLength 250
	 */
	name: string;
}

export interface CustomSaq {
	id?: string | null;
	templateVersion?: ReportType;
	name?: string | null;
	clientId?: string | null;
	summaryIds?: SummarySaq[] | null;
	applicableIds?: string[] | null;
}

export interface CustomSaqInputModel {
	id?: string | null;
	templateVersion?: ReportType;
	name?: string | null;
	clientId?: string | null;
}

export interface CustomSaqListSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: CustomSaq[] | null;
}

export interface CustomSaqSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: CustomSaq;
}

export interface DataOrder {
	isAscending?: boolean;
	propertyName?: string | null;
}

/** @format int32 */
export enum DaysOfWeek {
	Sunday = 1,
	Monday = 2,
	Tuesday = 4,
	Wednesday = 8,
	Thursday = 16,
	Friday = 32,
	Saturday = 64,
}

export interface DeleteUserModel {
	/** @minLength 1 */
	userName: string;
}

export interface DemoEnvironmentDetailsModel {
	commonUserPassword?: string | null;
	demoUserModels?: DemoUserModel[] | null;
}

export interface DemoEnvironmentDetailsModelSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: DemoEnvironmentDetailsModel;
}

export interface DemoEnvironmentModel {
	companyName?: string | null;
	url?: string | null;
}

export interface DemoUserModel {
	tokenId?: string | null;
	description?: string | null;
}

export interface DetailBase {
	description?: string | null;
	isPreset?: boolean;
	/** @format int32 */
	rating?: number;
}

export interface DockerContainerInfo {
	status?: string | null;
	startedAt?: string | null;
	image?: string | null;
}

/** @format int32 */
export enum DownloadFileType {
	Word = 119,
	Excel = 120,
	Archive = 122,
}

export interface EditUserInfoModel {
	/** @minLength 1 */
	firstName: string;
	/** @minLength 1 */
	lastName: string;
	responsibilityArea?: ResponsibilityArea;
	customCredentials?: string | null;
	mentorName?: string | null;
	organization?: string | null;
	phoneNumber?: string | null;
	clientId?: string | null;
	isPrimaryClient?: boolean;
}

export interface EmailAddress {
	name?: string | null;
	email?: string | null;
}

export interface EmailInfo {
	/** @format int64 */
	date?: number;
	status?: EmailStatus;
	to?: string | null;
	subject?: string | null;
}

export interface EmailInfoArraySuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: EmailInfo[] | null;
}

export interface EmailServiceStatus {
	connected?: boolean;
	additionalInfo?: StringStringKeyValuePair[] | null;
}

export interface EmailServiceStatusSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: EmailServiceStatus;
}

/** @format int32 */
export enum EmailStatus {
	New = 0,
	Sent = 1,
	Error = 2,
	Scheduled = 3,
}

export interface EmailTestMessage {
	subject?: string | null;
	message?: string | null;
	/** @format date-time */
	date?: string;
}

export interface EnableTfaSuccessResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	sharedKey?: string | null;
	qrCodeUri?: string | null;
	recoveryCodes?: string[] | null;
}

export interface EntryAuthor {
	authorId?: string | null;
	authorName?: string | null;
	authorType?: UserType;
	role?: Roles;
	authorProjectRole?: Roles;
	authorProjectSubRole?: ProjectSubRoles;
}

export interface EnvironmentalAnalysis {
	/** @format double */
	getScore?: number;
	threatRiskConditions?: ThreatRiskConditionDetail[] | null;
}

export interface EvidenceLocationSearch {
	location?: string | null;
	channelName?: string | null;
}

export interface ExcelAlignment {
	vertical?: XLAlignmentVerticalValues;
	horizontal?: XLAlignmentHorizontalValues;
	wrapText?: boolean;
	shrinkToFit?: boolean;
}

export interface ExcelAttributes {
	style?: ExcelStyle;
	columnSizes?: number[] | null;
	rowSizes?: number[] | null;
}

export interface ExcelFill {
	color?: string | null;
}

export interface ExcelFont {
	bold?: boolean;
	italic?: boolean;
}

export interface ExcelStyle {
	fill?: ExcelFill;
	alignment?: ExcelAlignment;
	font?: ExcelFont;
}

export interface FeatureDetail {
	description?: string | null;
}

export interface FileInputModel {
	/** @format binary */
	file?: File | null;
}

/** @format int32 */
export enum FileSystemType {
	OsFileSystem = 0,
	AwsS3 = 1,
	GridFS = 2,
}

export interface FullInterviewModel {
	interview?: InterviewModel;
	attendees?: InterviewAttendeeModel[] | null;
	items?: NewInterviewItem[] | null;
}

export interface FullInterviewModelSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: FullInterviewModel;
}

export interface GetSharedKeySuccessResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	sharedKey?: string | null;
	qrCodeUri?: string | null;
}

export interface GlobalOptions {
	/** @default true */
	telemetryEnabled?: boolean;
	workflowQAEnabled?: boolean;
	/** @default "en-US" */
	culture?: string | null;
	brand?: Brand;
	useRequirementNumberInEvidenceRef?: boolean;
	/** @default true */
	useIncompleteRocWatermark?: boolean;
	useDescriptionForEvidenceRef?: boolean;
	useLocationForEvidenceRef?: boolean;
	allowResponsesWithoutChannel?: boolean;
	projectTypeNaMessageTemplate?: string | null;
}

export interface GlobalOptionsSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: GlobalOptions;
}

export interface HelpMessage {
	id?: string | null;
	rocItemId?: string | null;
	message?: string | null;
	pinned?: boolean;
	isVisibleToCustomer?: boolean;
}

export interface Impact {
	reference?: string | null;
	description?: string | null;
	details?: DetailBase[] | null;
	/** @format double */
	overallSeverityOfImpact?: number;
}

export interface InterviewAttendeeModel {
	id?: string | null;
	/** @minLength 1 */
	name: string;
	clientId?: string | null;
	responsibilityArea: ResponsibilityArea;
	status?: ContactStatus;
	jobTitle?: string | null;
	organization?: string | null;
	phone?: string | null;
	/** @format email */
	email?: string | null;
	isISA?: boolean;
	isPrimary?: boolean;
	/** @format int32 */
	index?: number;
	isPendingDelete?: boolean;
	projectId?: string | null;
	itemId?: string | null;
	interviewId?: string | null;
	attendeeType?: InterviewAttendeeType;
	projectRole?: Roles;
	subRole?: ProjectSubRoles;
	sendCarbonCopyToEmails?: EmailAddress[] | null;
}

export interface InterviewAttendeeModelListSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: InterviewAttendeeModel[] | null;
}

/** @format int32 */
export enum InterviewAttendeeType {
	Contact = 0,
	User = 1,
	WriteIn = 2,
}

export interface InterviewInputModel {
	projectId?: string | null;
	/** @minLength 1 */
	subject: string;
	/** @format date-time */
	date: string;
	/** @format date-time */
	endDate: string;
	location?: string | null;
	organizerName?: string | null;
	onlineMeetingDetails?: string | null;
	email?: string | null;
	notes?: string | null;
	topicsCovered?: string | null;
	spentOnSite?: boolean;
	responsibilityArea?: ResponsibilityArea;
	notifyByEmail?: boolean;
	contactsIds?: string[] | null;
	userIds?: string[] | null;
	rocItemsIds?: string[] | null;
	writeIns?: InterviewAttendeeModel[] | null;
}

export interface InterviewListResult {
	interviews?: InterviewModel[] | null;
	/** @format int32 */
	pastInterviews?: number;
	/** @format int32 */
	futureInterviews?: number;
}

export interface InterviewListResultSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: InterviewListResult;
}

export interface InterviewModel {
	projectId?: string | null;
	/** @minLength 1 */
	subject: string;
	/** @format date-time */
	date: string;
	/** @format date-time */
	endDate: string;
	location?: string | null;
	organizerName?: string | null;
	onlineMeetingDetails?: string | null;
	email?: string | null;
	notes?: string | null;
	topicsCovered?: string | null;
	spentOnSite?: boolean;
	responsibilityArea?: ResponsibilityArea;
	notifyByEmail?: boolean;
	contactsIds?: string[] | null;
	userIds?: string[] | null;
	rocItemsIds?: string[] | null;
	writeIns?: InterviewAttendeeModel[] | null;
	id?: string | null;
	/** @minLength 1 */
	createdById: string;
	/** @format int32 */
	statusId?: number | null;
	/** @format date-time */
	createdDate?: string | null;
	/** @format date-time */
	modifiedDate?: string | null;
	projectName?: string | null;
}

export interface InvitationNewModel {
	role: Roles;
	projectSubRoles?: ProjectSubRoles;
	projectId?: string | null;
	companyId?: string | null;
	clientId?: string | null;
	contactId?: string | null;
	/** @format email */
	email?: string | null;
}

/** @format int32 */
export enum ItemStatus {
	NotStarted = 1,
	WaitingForCustomer = 2,
	InterviewScheduled = 4,
	WaitingForQsa = 8,
	ReadyForPeerReview = 16,
	ChangesRequested = 32,
	Approved = 64,
	Ignore = 128,
	ReadyForQA = 256,
}

export interface LicenseModel {
	/** @format int32 */
	id: number;
	/**
	 * @minLength 1
	 * @maxLength 250
	 */
	name: string;
	host?: string | null;
	/** @maxLength 512 */
	communicationToken?: string | null;
	enabled: boolean;
	/** @default false */
	deleted: boolean;
	/**
	 * @deprecated
	 * @format int32
	 */
	maxAccountsCount?: number;
	/** @format int32 */
	maxUsersGACount: number;
	/** @format int32 */
	maxUsersQSACount: number;
	/** @format int32 */
	maxUsersNonQSACount: number;
	/** @format int32 */
	maxServerInstances: number;
	/** @format date-time */
	createdDate?: string;
	/** @format int32 */
	usersGACount?: number;
	/** @format int32 */
	usersQSACount?: number;
	/** @format int32 */
	usersNonQSACount?: number;
	/** @format int32 */
	projectsCount?: number;
	/** @format int32 */
	companiesCount?: number;
	isDockerBridgeConfigured?: boolean;
	/** @maxLength 128 */
	awsEcrAccountName?: string | null;
}

/** @format int32 */
export enum LicenseStatus {
	Valid = 0,
	MaxUsersExceeded = 1,
	Expired = 2,
	Error = -1,
}

export interface LicensedServer {
	/** @format int32 */
	id: number;
	/** @format int32 */
	licenseModelId: number;
	license?: LicenseModel;
	/**
	 * @minLength 1
	 * @maxLength 128
	 */
	serverId: string;
	/** @format date-time */
	lastTimeRegistered: string;
}

export interface Licensing {
	status?: LicenseStatus;
	/** @format date-time */
	startsOn?: string;
	/** @format date-time */
	expiresOn?: string;
	info?: string | null;
	/** @format int32 */
	gaCount?: number;
	/** @format int32 */
	qsaCount?: number;
	/** @format int32 */
	nonQSACount?: number;
	/** @format int32 */
	maxUsersGACount?: number;
	/** @format int32 */
	maxUsersQSACount?: number;
	/** @format int32 */
	maxUsersNonQSACount?: number;
	/** @format int32 */
	maxServerInstances?: number;
	features?: string[] | null;
	featuresDetailed?: Record<string, FeatureDetail>;
	responseSource?: string | null;
	build?: string | null;
	license?: string | null;
	communicationToken?: string | null;
	/** @format date-time */
	checkExpirationDate?: string | null;
}

export interface LicensingSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: Licensing;
}

export interface LoginModel {
	/** @minLength 1 */
	login: string;
	/**
	 * @format password
	 * @minLength 1
	 */
	password: string;
	rememberMe?: boolean;
}

export interface LoginSuccessResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	token?: string | null;
	tfaOptions?: string[] | null;
	isRememberMeSupported?: boolean;
	isRememberBrowserSupported?: boolean;
}

export interface LoginWithCodeModel {
	/** @minLength 1 */
	login: string;
	/**
	 * @format password
	 * @minLength 1
	 */
	password: string;
	/**
	 * @format string
	 * @minLength 1
	 */
	code: string;
	/** @minLength 1 */
	twoFactorProvider: string;
	rememberMe?: boolean;
}

export interface MediaFile {
	id?: string | null;
	description?: string | null;
	type?: RocDataType;
	/** @minLength 1 */
	projectId: string;
	referenceNumber?: string | null;
	referenceItems?: NumberingSearch[] | null;
	nameOrUrl?: string | null;
	fileName?: string | null;
	createdBy?: EntryAuthor;
	webUrl?: string | null;
	/** @format date-time */
	actualDateTime?: string;
	/** @format date-time */
	uploadDateTime?: string;
	version?: string | null;
	fileSystemType?: FileSystemType;
	contentType?: string | null;
	checkSum?: string | null;
	sampleSetId?: string | null;
	/** @format int32 */
	requirementNumber?: number;
	mediaFileType?: MediaFileType;
}

/** @format int32 */
export enum MediaFileType {
	Default = 0,
	SampleSetTrackingSheet = 1,
	WorkflowAttachment = 2,
	ReviewedAttachment = 3,
}

export interface ModelFilterInput {
	/** @format int32 */
	page?: number;
	/** @format int32 */
	pageSize?: number;
	dataOrder?: DataOrder;
	clientResponsibilities?: ResponsibilityArea;
	saqEnabled?: boolean;
	interviewArea?: ResponsibilityArea;
	evidenceArea?: ResponsibilityArea;
	/** @format date-time */
	dateFrom?: string | null;
	/** @format date-time */
	dateUntil?: string | null;
	authorId?: string | null;
	itemStatus?: ItemStatus;
	summary?: SummaryOfAssessmentFindingsState;
	rocWarning?: RocWarningType;
	isWithUnexpiredRequests?: boolean;
	isWithRequestsTargetedAtMe?: boolean;
	requestTargetedAt?: string | null;
	isWithoutInput?: boolean;
	isWithCCW?: boolean;
	isWithIncompleteCCW?: boolean;
	isWithCustomApproach?: boolean;
	ignoreSystemWorkflows?: boolean;
	isItemFilterActive?: boolean;
	isOnlySaqFilterActive?: boolean;
	isAnyItemFilterActive?: boolean;
	isWorkflowFilterActive?: boolean;
}

/** @format int32 */
export enum ModelType {
	Unspecified = 0,
	Merchant = 1,
	ServiceProvider = 2,
	SharedHostingProvider = 4,
	LowReadiness = 8,
	ModerateReadiness = 16,
	HighReadiness = 32,
	Scope = 64,
}

export interface MpaActionModel {
	id?: string | null;
	actionType?: ActionType;
	/** @format int32 */
	approverRolesMask?: number;
	/** @format int32 */
	approveRequiredRolesMask?: number;
	isEnabled?: boolean;
}

export interface MpaActionModelListSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: MpaActionModel[] | null;
}

export interface MpaPendingRequestsModel {
	/** @format int32 */
	pendingRequestsCount?: number;
}

export interface MpaPendingRequestsModelSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: MpaPendingRequestsModel;
}

export interface MpaRequestModel {
	id?: string | null;
	actionType?: ActionType;
	approverDecision?: ApproverDecision;
	resourceId?: string | null;
	resourceDescription?: string | null;
	requester?: string | null;
	approver?: string | null;
	/** @format date-time */
	requestDateTime?: string;
	/** @format date-time */
	decisionDateTime?: string;
}

export interface MpaRequestModelListSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: MpaRequestModel[] | null;
}

export interface NewAttachmentFile {
	/** @format binary */
	file?: File | null;
	webUrl?: string | null;
	description?: string | null;
	evidenceLocation?: string | null;
	/** @format date-time */
	actualDateTime?: string;
	version?: string | null;
}

export interface NewBrandFile {
	/** @format binary */
	file?: File | null;
	brandFileType?: BrandFileType;
}

export interface NewInterviewItem {
	id?: string | null;
	chapterId?: string | null;
	responsibilityArea?: ResponsibilityArea;
	itemDescription?: string | null;
	numbering?: string | null;
}

export interface NewInterviewItemArraySuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: NewInterviewItem[] | null;
}

/** @format int32 */
export enum NodeType {
	Template = 1,
	Ephemeral = 2,
	Chapter = 4,
	ContentHolder = 8,
	Row = 16,
	Column = 32,
	ItemTemplate = 64,
	Array = 256,
}

export interface NonComplianceReason {
	paymentChannelId?: string | null;
	reason?: string | null;
	resolutionRecommendation?: string | null;
	priority?: RemediationPriority;
}

export interface NonComplianceReasonItemReasonsInput {
	reasons?: NonComplianceReason[] | null;
}

export interface NumberingSearch {
	itemId?: string | null;
	name?: string | null;
	numbering?: string | null;
	chapterId?: string | null;
	subItemName?: string | null;
	evidenceLocations?: EvidenceLocationSearch[] | null;
}

export interface NumberingSearchArraySuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: NumberingSearch[] | null;
}

export interface NumberingSearchListSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: NumberingSearch[] | null;
}

export interface NumberingSearchSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: NumberingSearch;
}

export interface ObjectSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: any;
}

export interface ProjectDetailsModel {
	id?: string | null;
	/** @minLength 1 */
	name: string;
	/** @minLength 1 */
	clientId: string;
	version?: ReportType;
	channels?: Channel[] | null;
	createdById?: string | null;
	/** @format int32 */
	statusId?: number | null;
	isActive?: boolean;
	isClosed?: boolean;
	role?: Roles;
	projectSubRoles?: ProjectSubRoles;
	assignedUsers?: UserProjectModel[] | null;
	basedOn?: string | null;
	scopeProjectId?: string | null;
	group?: ReportGroup;
	projectType?: ModelType;
	/** @format date-time */
	startDate?: string | null;
	/** @format date-time */
	createdDate?: string | null;
	/** @format date-time */
	modifiedDate?: string | null;
	individualsInterviewedPrefix?: string | null;
	documentationReviewedPrefix?: string | null;
	companyId?: string | null;
	companyName?: string | null;
	clientName?: string | null;
	userName?: string | null;
	statusName?: string | null;
	progress?: Record<string, number | null>;
}

export interface ProjectDetailsModelArraySuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: ProjectDetailsModel[] | null;
}

export interface ProjectInfoAccess {
	projectName?: string | null;
	type?: ModelType;
	version?: ReportType;
	clientId?: string | null;
	clientName?: string | null;
	projectNaMessage?: string | null;
	/** @format date-time */
	startDate?: string | null;
	/** @format date-time */
	endDate?: string | null;
	roles?: Roles;
	workflowRole?: WorkflowRole;
	paymentChannels?: Channel[] | null;
	customSaq?: CustomSaq[] | null;
	appliedScopeInfo?: AppliedScopeInfo;
}

export interface ProjectInfoAccessSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: ProjectInfoAccess;
}

export interface ProjectModel {
	id?: string | null;
	/** @minLength 1 */
	name: string;
	/** @minLength 1 */
	clientId: string;
	version?: ReportType;
	channels?: Channel[] | null;
	createdById?: string | null;
	/** @format int32 */
	statusId?: number | null;
	isActive?: boolean;
	isClosed?: boolean;
	role?: Roles;
	projectSubRoles?: ProjectSubRoles;
	assignedUsers?: UserProjectModel[] | null;
	basedOn?: string | null;
	scopeProjectId?: string | null;
	group?: ReportGroup;
	projectType?: ModelType;
	/** @format date-time */
	startDate?: string | null;
	/** @format date-time */
	createdDate?: string | null;
	/** @format date-time */
	modifiedDate?: string | null;
	individualsInterviewedPrefix?: string | null;
	documentationReviewedPrefix?: string | null;
}

export interface ProjectResponsibilityAreaModel {
	hasIncompleteResponses?: boolean;
	hasIncompleteEvidence?: boolean;
	hasIncompleteInterviews?: boolean;
	responsibilityArea?: ResponsibilityArea;
	contacts?: ContactModel[] | null;
	clientUsers?: UserInfo[] | null;
}

export interface ProjectResponsibilityAreaModelIEnumerableSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: ProjectResponsibilityAreaModel[] | null;
}

/** @format int32 */
export enum ProjectSubRoles {
	None = 0,
	Reviewer = 1,
	Lead = 2,
	QA = 4,
}

export interface ProjectUserInfo {
	/** @minLength 1 */
	firstName: string;
	/** @minLength 1 */
	lastName: string;
	responsibilityArea?: ResponsibilityArea;
	customCredentials?: string | null;
	mentorName?: string | null;
	organization?: string | null;
	id?: string | null;
	userName?: string | null;
	clientName?: string | null;
	/** @format date-time */
	creationDate?: string;
	roles?: Roles[] | null;
	active?: boolean;
	emailConfirmed?: boolean;
	company?: ShortCompanyInfo[] | null;
	email?: string | null;
	phoneNumber?: string | null;
	userClient?: UserClientModel;
	/** @format date-time */
	lockoutEnd?: string | null;
	twoFactorEnabled?: boolean;
	userProject?: UserProjectModel;
}

export interface ProjectUserInfoArraySuccessResultPagingResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: ProjectUserInfo[] | null;
	/** @format int32 */
	pagesCount?: number;
}

export interface ReasonBase {
	paymentChannelId?: string | null;
	reason?: string | null;
}

export interface ReasonBaseItemReasonsInput {
	reasons?: ReasonBase[] | null;
}

export interface RegisterByTokenModel {
	/** @minLength 1 */
	firstName: string;
	/** @minLength 1 */
	lastName: string;
	/** @format tel */
	phone?: string | null;
	/** @minLength 1 */
	token: string;
	organization?: string | null;
	customCredentials?: string | null;
}

export interface RegisterWithEmailByAdminModel {
	/** @minLength 1 */
	firstName: string;
	/** @minLength 1 */
	lastName: string;
	/** @format tel */
	phone?: string | null;
	/** @minLength 1 */
	userName: string;
	/** @format email */
	email?: string | null;
	companyId?: string | null;
	clientId?: string | null;
	projectId?: string | null;
	role: Roles;
	projectSubRoles?: ProjectSubRoles;
}

export interface RegisterWithEmailByInvitationModel {
	/** @minLength 1 */
	firstName: string;
	/** @minLength 1 */
	lastName: string;
	/** @format tel */
	phone?: string | null;
	/** @minLength 1 */
	userName: string;
	/** @minLength 1 */
	token: string;
	/**
	 * @format password
	 * @minLength 6
	 * @maxLength 100
	 */
	password: string;
	/** @format email */
	email?: string | null;
}

/** @format int32 */
export enum ReleaseType {
	Stable = 0,
	Alpha = 1,
	Beta = 2,
	Gamma = 3,
	Pilot = 100,
	Staging = 101,
	Unstable = 102,
	Development = 103,
}

export interface RemediationPlanItem {
	taskOrAction?: string | null;
	stakeholders?: ContactModel[] | null;
	stakeholdersPlain?: string | null;
	resourcesNeeded?: string | null;
	constrains?: string | null;
	/** @format date-time */
	targetDate?: string;
	progress?: RiskRemediationStatus;
}

/** @format int32 */
export enum RemediationPriority {
	Low = 0,
	Medium = 1,
	High = 2,
}

export interface RemoteClientDockerInfo {
	version?: string | null;
	date?: string | null;
	/** @format int32 */
	amiVersion?: number;
	releaseType?: string | null;
	containers?: DockerContainerInfo[] | null;
}

export interface RemoteClientDockerInfoSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: RemoteClientDockerInfo;
}

export interface RemoteClientInfo {
	version?: string | null;
	date?: string | null;
	/** @format int32 */
	amiVersion?: number;
}

export interface RemoteClientInfoSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: RemoteClientInfo;
}

export interface RemoteClientReleaseInfo {
	version?: string | null;
	date?: string | null;
	/** @format int32 */
	amiVersion?: number;
	releaseType?: ReleaseType;
}

export interface RemoteCommand {
	kind: RemoteCommandKind;
	/** @minLength 1 */
	command: string;
	/** @minLength 1 */
	description: string;
	/** @minLength 1 */
	id: string;
	/** @format date-time */
	createdDate?: string;
	approval?: RemoteCommandApproval;
	output?: string | null;
	error?: string | null;
	/** @format date-time */
	executedDate?: string | null;
}

/** @format int32 */
export enum RemoteCommandApproval {
	None = 0,
	Approved = 1,
	Rejected = -1,
}

export interface RemoteCommandInputModel {
	kind: RemoteCommandKind;
	/** @minLength 1 */
	command: string;
	/** @minLength 1 */
	description: string;
}

/** @format int32 */
export enum RemoteCommandKind {
	Shell = 0,
	Sql = 1,
	NoSql = 2,
	DbBackup = 3,
}

export interface RemoteCommandListSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: RemoteCommand[] | null;
}

export interface RemoteCommandSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: RemoteCommand;
}

/** @format int32 */
export enum ReportAutoComplete {
	None = 0,
	ControlItem = 1,
	RemoteTesting = 2,
	SegmentationUsed = 4,
	SegmentationNotUsed = 8,
	RequirementsNotTested = 16,
	PciValidatedProductInUse = 32,
	SubcontractorsInvolved = 64,
	SamplingNotUsed = 128,
	SamplingUsed = 256,
	ExternalScanInitialValidation = 512,
	InternalScanInitialValidation = 1024,
}

export interface ReportContact {
	id?: string | null;
	/** @minLength 1 */
	name: string;
	clientId?: string | null;
	responsibilityArea: ResponsibilityArea;
	status?: ContactStatus;
	jobTitle?: string | null;
	organization?: string | null;
	phone?: string | null;
	/** @format email */
	email?: string | null;
	isISA?: boolean;
	isPrimary?: boolean;
	/** @format int32 */
	index?: number;
	isPendingDelete?: boolean;
	projectId?: string | null;
	itemId?: string | null;
	interviewId?: string | null;
	interviewItemIds?: string[] | null;
}

/** @format int32 */
export enum ReportGroup {
	Items = 0,
	Attachments = 1,
	Workflows = 2,
	ROCContactInformation = 4,
	ROCRemoteAssessmentActivities = 8,
	ROCAdditionalServices = 16,
	ROCUseOfSubcontractors = 32,
	ROCAdditionalInformation = 64,
	ROCBusinessOverview = 128,
	ROCAssessorValidationOfDefinedScopeAccuracy = 256,
	ROCSegmentation = 512,
	ROCPCISSCValidatedProductsAndSolutions = 1024,
	ROCSampling = 2048,
	ROCNetworkDiagrams = 4096,
	ROCAccountDataFlowDiagrams = 8192,
	ROCStorageOfAccountData = 16384,
	ROCInScopeThirdPartyServiceProviders = 32768,
	ROCInScopeNetworks = 65536,
	ROCInScopeLocationsFacilities = 131072,
	ROCInScopeBusinessFunctions = 262144,
	ROCInScopeSystemComponentTypes = 524288,
	ROCEvidenceRetention = 1048576,
	ROCFindings = 2097152,
	ROCFindingsRequirementsOnly = 4194304,
	Scope = 8388608,
	ROCAttestations = 16777216,
}

/** @format int32 */
export enum ReportType {
	PCI_DSS_v321 = 0,
	PCI_DSS_v4 = 1,
	FedRAMP_rev5 = 2,
	PCI_DSS_v321r2 = 3,
	PCI_DSS_Scope_Builder = 4,
	PCI_DSS_v4r1_AOC_SP_r2 = 5,
}

export interface ReportUser {
	/** @minLength 1 */
	firstName: string;
	/** @minLength 1 */
	lastName: string;
	responsibilityArea?: ResponsibilityArea;
	customCredentials?: string | null;
	mentorName?: string | null;
	organization?: string | null;
	id?: string | null;
	userName?: string | null;
	clientName?: string | null;
	/** @format date-time */
	creationDate?: string;
	roles?: Roles[] | null;
	active?: boolean;
	emailConfirmed?: boolean;
	company?: ShortCompanyInfo[] | null;
	email?: string | null;
	phoneNumber?: string | null;
	userClient?: UserClientModel;
	/** @format date-time */
	lockoutEnd?: string | null;
	twoFactorEnabled?: boolean;
	userProject?: UserProjectModel;
	interviewItemIds?: string[] | null;
}

export interface Request {
	/**
	 * @minLength 1
	 * @maxLength 36
	 */
	id: string;
	targets?: RequestTarget[] | null;
	/** @format date-time */
	validUntil?: string;
}

export interface RequestEmailModel {
	email?: string | null;
	responsibility?: ResponsibilityArea;
}

export interface RequestInputModel {
	userIds?: string[] | null;
	contactIds?: string[] | null;
	emails?: RequestEmailModel[] | null;
	/** @format date-time */
	validUntil?: string | null;
}

export interface RequestTarget {
	/**
	 * @minLength 1
	 * @maxLength 36
	 */
	id: string;
	userId?: string | null;
	contactId?: string | null;
	email?: RequestEmailModel;
}

export interface RequestTempLogin {
	projectId?: string | null;
	chapterId?: string | null;
	itemId?: string | null;
	logout?: boolean;
	responseToken?: string | null;
	userName?: string | null;
	contactName?: string | null;
}

export interface RequestTempLoginSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: RequestTempLogin;
}

export interface RequirementApproach {
	requirementId?: string | null;
	type?: RequirementApproachType;
	aspects?: string | null;
	linkedControlsMatrixIds?: string[] | null;
	linkedRequirements?: RequirementRocItem[] | null;
	/**
	 * @minLength 4
	 * @maxLength 128
	 */
	customizedControlName?: string | null;
}

/** @format int32 */
export enum RequirementApproachType {
	Defined = 0,
	Customized = 1,
}

export interface RequirementData {
	id?: string | null;
	rocDataType?: RocDataType;
	/** @format date-time */
	editTime?: string | null;
	createdBy?: EntryAuthor;
	modifiedBy?: EntryAuthor;
	paymentChannelId?: string | null;
	requestId?: string | null;
	disclosureSummary?: string | null;
	appliedScopeInfo?: AppliedScopeInfo;
	summaryOfAssessmentFindingsState?: SummaryOfAssessmentFindingsState;
	whySelected?: string | null;
}

export interface RequirementRocItem {
	/** @format int32 */
	level?: number;
	parentsIds?: string[] | null;
	editorParentIds?: string[] | null;
	projectId?: string | null;
	itemId?: string | null;
	parentKey?: string | null;
	id?: RocItemId;
	/** @format int32 */
	order?: number;
	/** @format date-time */
	updateTime?: string;
	modifiedBy?: EntryAuthor;
	isDeleted?: boolean;
	isPermanentDeletionRequested?: boolean;
	untracked?: boolean;
	version?: ReportType;
	nodeType?: NodeType;
	group?: ReportGroup;
	standardHints?: HelpMessage[] | null;
	summaryId?: string | null;
	nonComplianceReasons?: NonComplianceReason[] | null;
	nonCompliant?: boolean;
	notApplicableReasons?: ReasonBase[] | null;
	notApplicable?: boolean;
	parentProgressItemId?: string | null;
	ignoreProgress?: boolean;
	forceProgress?: boolean;
	status?: ItemStatus;
	rocWarnings?: RocWarningType;
	customWarnings?: RocWarningType;
	hiddenWarnings?: RocWarningType;
	modelType?: ModelType;
	/** @deprecated */
	hasAutoEvent?: boolean;
	subType?: string | null;
	readPermissionMask?: Roles;
	writePermissionMask?: Roles;
	variableName?: string | null;
	autoComplete?: ReportAutoComplete;
	viewStyleInformation?: StyleInformation;
	style?: StyleInformation;
	contentsInfo?: RocContentsInfo;
	useDifferentValuesPerPaymentChannels?: boolean | null;
	isHiddenInReport?: boolean;
	responsibilityArea?: ResponsibilityArea;
	linkedItemId?: string | null;
	saq?: Saq;
	isHintsEnabled?: boolean;
	isGuidanceEnabled?: boolean;
	isLookUpDisabled?: boolean;
	isRequiredForEvidences?: boolean;
	isAutoGenerated?: boolean;
	isAutoModified?: boolean;
	/** @format date-time */
	lastAutofillDate?: string | null;
	needsWorkflowOnFill?: boolean;
	itemDescription?: string | null;
	interviewId?: string | null;
	/** @format int32 */
	requirementNumber?: number;
	numberingOrderBy?: string | null;
	excelAttributes?: ExcelAttributes;
	appliedScopeInfo?: AppliedScopeInfo;
	multipleResponses?: boolean;
	isReadOnly?: boolean;
	attachmentPrefix?: string | null;
	textBlockRocItemId?: string | null;
	data?: RequirementData[] | null;
	hasResponses?: boolean;
	type?: RocItemType;
	rocDataType?: RocDataType;
	requirement?: string | null;
	customizedApproachUsed?: boolean;
	isCustomizedApproachForbidden?: boolean;
	compensatingControlUsed?: boolean;
	customizedApproachObjective?: string | null;
	guidance?: string | null;
	applicabilityNotes?: string | null;
}

export interface ResetPasswordModel {
	/**
	 * @format password
	 * @minLength 6
	 * @maxLength 100
	 */
	password: string;
	/** @minLength 1 */
	token: string;
}

/** @format int32 */
export enum Resource {
	Client = 1,
	Company = 2,
	Project = 4,
	User = 8,
	Template = 16,
	AuditLog = 32,
	Report = 64,
	Profile = 128,
	Contact = 256,
	Interview = 512,
	Evidence = 1024,
	Channel = 2048,
	Response = 4096,
	HelpMessage = 8192,
	StatusReport = 16384,
	Workflow = 32768,
	Token = 65536,
	InterviewAttendee = 131072,
	MpaRequest = 262144,
}

/** @format int32 */
export enum ResponsibilityArea {
	AntiVirusSoftwareConfigurationAndManagement = 1,
	AsvScans = 2,
	DatabaseAdministratorsOwners = 4,
	EncryptionOfDataInTransit = 8,
	EncryptionStandardsAndImplementationForStorageOfCardholderData = 16,
	FirewallAndRoutersConfigurationAndManagement = 32,
	HumanResourcesAndTrainingCoordinator = 64,
	IncidentResponseAndLogMonitoring = 128,
	InformationSecurityManagement = 256,
	InternalAndExternalVulnerabilityScanning = 512,
	NetworkArchitectureOperationsAndManagement = 1024,
	PatchInstallationManagement = 2048,
	PenetrationTesting = 4096,
	PhysicalAccessControl = 8192,
	ServersAndWorkstationsConfigurationAndManagement = 16384,
	SoftwareDevelopmentProcesses = 32768,
	UserAccountManagement = 65536,
	UsersPrivilegeManagement = 131072,
	WirelessConfigurationAndManagement = 262144,
	SystemArchitectsCloudArchitects = 524288,
}

export interface ResponsibilityFilter {
	interviewResponsibilities?: ResponsibilityArea[] | null;
	evidenceResponsibilities?: ResponsibilityArea[] | null;
}

export interface ResponsibilityFilterSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: ResponsibilityFilter;
}

export interface RftCloneInputModel {
	/**
	 * @format string
	 * @minLength 1
	 */
	name: string;
	customRftId?: string | null;
	rftId?: Saq;
	reportType?: ReportType;
}

export interface RiskAnalysis {
	reference?: string | null;
	title?: string | null;
	description?: string | null;
	/** @format date-time */
	dateRegistered?: string;
	/** @format date-time */
	targetDate?: string;
	accountability?: string | null;
	currentRemediationStatus?: RiskRemediationStatus;
	/** @format double */
	overallRiskScore?: number;
	impacts?: Impact[] | null;
	/** @format double */
	overallImpactOfRealisation?: number;
	enterpriseResilienceDetails?: DetailBase[] | null;
	/** @format double */
	overallEnterpriseResilienceInverse?: number;
	targetAttractivenessDetails?: DetailBase[] | null;
	/** @format double */
	overallTargetAttractivenessInverse?: number;
	/** @format double */
	environmentScore?: number;
	/** @format double */
	overallLikelihoodOfRealisation?: number;
	controlStrengths?: ControlStrength[] | null;
	/** @format double */
	overallControlStrength?: number;
	/** @format double */
	dpcReductionInLikelihood?: number;
	/** @format double */
	rrrReductionInImpact?: number;
	remediationPlan?: RemediationPlanItem[] | null;
	assessorNotes?: string | null;
}

export interface RiskRegisterAndAssessment {
	id?: string | null;
	/** @minLength 1 */
	name: string;
	/** @minLength 1 */
	clientId: string;
	/** @format date-time */
	createdDate?: string;
	author?: EntryAuthor;
	environmentalAnalysis?: EnvironmentalAnalysis;
	riskAnalyses?: RiskAnalysis[] | null;
}

export interface RiskRegisterAndAssessmentListSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: RiskRegisterAndAssessment[] | null;
}

export interface RiskRegisterAndAssessmentSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: RiskRegisterAndAssessment;
}

/** @format int32 */
export enum RiskRemediationStatus {
	NotStarted = 0,
	OnTrack = 1,
	Lagging = 2,
	Overdue = 3,
	Completed = 4,
}

/** @format int32 */
export enum RiskToolEntryType {
	RiskAnalysis = 0,
	Impact = 1,
	ControlStrength = 2,
	RemediationPlan = 3,
}

export interface RocContentsInfo {
	/** @format int32 */
	level?: number;
	header?: string | null;
	headerLocalized?: Record<string, string | null>;
	numbering?: string | null;
	pageNumberType?: ContentsPageNumberType;
	pageFooterText?: string | null;
}

export interface RocData {
	id?: string | null;
	rocDataType?: RocDataType;
	/** @format date-time */
	editTime?: string | null;
	createdBy?: EntryAuthor;
	modifiedBy?: EntryAuthor;
	paymentChannelId?: string | null;
	requestId?: string | null;
	disclosureSummary?: string | null;
	appliedScopeInfo?: AppliedScopeInfo;
}

/** @format int32 */
export enum RocDataAction {
	Insert = 0,
	Update = 1,
	Delete = 2,
}

export interface RocDataInfo {
	id?: string | null;
	rocDataType?: RocDataType;
	/** @format date-time */
	editTime?: string | null;
	createdBy?: EntryAuthor;
	modifiedBy?: EntryAuthor;
	paymentChannelId?: string | null;
	requestId?: string | null;
	disclosureSummary?: string | null;
	appliedScopeInfo?: AppliedScopeInfo;
	attachmentId?: string | null;
}

export interface RocDataItem {
	/** @format int32 */
	level?: number;
	parentsIds?: string[] | null;
	editorParentIds?: string[] | null;
	projectId?: string | null;
	itemId?: string | null;
	parentKey?: string | null;
	id?: RocItemId;
	/** @format int32 */
	order?: number;
	/** @format date-time */
	updateTime?: string;
	modifiedBy?: EntryAuthor;
	isDeleted?: boolean;
	isPermanentDeletionRequested?: boolean;
	untracked?: boolean;
	version?: ReportType;
	nodeType?: NodeType;
	type?: RocItemType;
	group?: ReportGroup;
	standardHints?: HelpMessage[] | null;
	summaryId?: string | null;
	nonComplianceReasons?: NonComplianceReason[] | null;
	nonCompliant?: boolean;
	notApplicableReasons?: ReasonBase[] | null;
	notApplicable?: boolean;
	parentProgressItemId?: string | null;
	ignoreProgress?: boolean;
	forceProgress?: boolean;
	status?: ItemStatus;
	rocWarnings?: RocWarningType;
	customWarnings?: RocWarningType;
	hiddenWarnings?: RocWarningType;
	modelType?: ModelType;
	/** @deprecated */
	hasAutoEvent?: boolean;
	subType?: string | null;
	readPermissionMask?: Roles;
	writePermissionMask?: Roles;
	variableName?: string | null;
	autoComplete?: ReportAutoComplete;
	viewStyleInformation?: StyleInformation;
	style?: StyleInformation;
	contentsInfo?: RocContentsInfo;
	useDifferentValuesPerPaymentChannels?: boolean | null;
	isHiddenInReport?: boolean;
	responsibilityArea?: ResponsibilityArea;
	linkedItemId?: string | null;
	saq?: Saq;
	isHintsEnabled?: boolean;
	isGuidanceEnabled?: boolean;
	isLookUpDisabled?: boolean;
	isRequiredForEvidences?: boolean;
	isAutoGenerated?: boolean;
	isAutoModified?: boolean;
	/** @format date-time */
	lastAutofillDate?: string | null;
	needsWorkflowOnFill?: boolean;
	itemDescription?: string | null;
	interviewId?: string | null;
	/** @format int32 */
	requirementNumber?: number;
	numberingOrderBy?: string | null;
	excelAttributes?: ExcelAttributes;
	appliedScopeInfo?: AppliedScopeInfo;
	multipleResponses?: boolean;
	isReadOnly?: boolean;
	rocDataType?: RocDataType;
	hasResponses?: boolean;
	attachmentPrefix?: string | null;
	textBlockRocItemId?: string | null;
}

/** @format int32 */
export enum RocDataType {
	Bool = 0,
	Date = 1,
	Text = 2,
	SummaryOfAssessmentFindingsState = 3,
	MediaFile = 4,
	AssessorName = 5,
	SummaryOfFindingsState = 6,
	InterviewAttendee = 7,
	SampleSet = 8,
	Requirement = 9,
	ObservationEvidence = 10,
	SystemEvidence = 11,
	InterviewEvent = 12,
	RadioFlag = 13,
	SummaryOfRequirements = 14,
	DateRange = 15,
	FedRampControlSummary = 16,
	AocSummaryOfRequirements = 17,
}

export interface RocItem {
	/** @format int32 */
	level?: number;
	parentsIds?: string[] | null;
	editorParentIds?: string[] | null;
	projectId?: string | null;
	itemId?: string | null;
	parentKey?: string | null;
	id?: RocItemId;
	/** @format int32 */
	order?: number;
	/** @format date-time */
	updateTime?: string;
	modifiedBy?: EntryAuthor;
	isDeleted?: boolean;
	isPermanentDeletionRequested?: boolean;
	untracked?: boolean;
	version?: ReportType;
	nodeType?: NodeType;
	type?: RocItemType;
	group?: ReportGroup;
	standardHints?: HelpMessage[] | null;
	summaryId?: string | null;
	nonComplianceReasons?: NonComplianceReason[] | null;
	nonCompliant?: boolean;
	notApplicableReasons?: ReasonBase[] | null;
	notApplicable?: boolean;
	parentProgressItemId?: string | null;
	ignoreProgress?: boolean;
	forceProgress?: boolean;
	status?: ItemStatus;
	rocWarnings?: RocWarningType;
	customWarnings?: RocWarningType;
	hiddenWarnings?: RocWarningType;
	modelType?: ModelType;
	/** @deprecated */
	hasAutoEvent?: boolean;
	subType?: string | null;
	readPermissionMask?: Roles;
	writePermissionMask?: Roles;
	variableName?: string | null;
	autoComplete?: ReportAutoComplete;
	viewStyleInformation?: StyleInformation;
	style?: StyleInformation;
	contentsInfo?: RocContentsInfo;
	useDifferentValuesPerPaymentChannels?: boolean | null;
	isHiddenInReport?: boolean;
	responsibilityArea?: ResponsibilityArea;
	linkedItemId?: string | null;
	saq?: Saq;
	isHintsEnabled?: boolean;
	isGuidanceEnabled?: boolean;
	isLookUpDisabled?: boolean;
	isRequiredForEvidences?: boolean;
	isAutoGenerated?: boolean;
	isAutoModified?: boolean;
	/** @format date-time */
	lastAutofillDate?: string | null;
	needsWorkflowOnFill?: boolean;
	itemDescription?: string | null;
	interviewId?: string | null;
	/** @format int32 */
	requirementNumber?: number;
	numberingOrderBy?: string | null;
	excelAttributes?: ExcelAttributes;
	appliedScopeInfo?: AppliedScopeInfo;
}

export interface RocItemId {
	itemId?: string | null;
	rocModelId?: string | null;
}

export interface RocItemInterviewInfo {
	item?: NewInterviewItem;
	responsibleContacts?: InterviewAttendeeModel[] | null;
}

export interface RocItemInterviewInfoSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: RocItemInterviewInfo;
}

export interface RocItemSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: RocItem;
}

/** @format int32 */
export enum RocItemType {
	TextField = 0,
	Attachment = 1,
	Array = 2,
	Bool = 3,
	Date = 4,
	DateRange = 5,
	Container = 7,
	TextBlock = 8,
	Enum = 10,
	SummaryOfAssessmentFindingsState = 11,
	TableRow = 12,
	Requirement = 13,
	OneMultipleRow = 14,
	CompensatingControl = 15,
	SummaryOfFindingsState = 16,
	InterviewAttendee = 17,
	InterviewEvent = 18,
	RadioFlag = 19,
	SummaryOfRequirements = 20,
	FedRampControl = 22,
	OscalTextField = 23,
	OscalChoiceField = 24,
	FedRampControlSummary = 25,
	AocSummaryOfRequirements = 26,
}

export interface RocNextModel {
	requestChapterId?: string | null;
	requestItemId?: string | null;
	requestToken?: string | null;
}

export interface RocNextModelSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: RocNextModel;
}

export interface RocResponseLookUpModel {
	item?: RocDataItem;
	projectId?: string | null;
	projectName?: string | null;
	/** @format date-time */
	projectStartDate?: string | null;
	mediaFiles?: MediaFile[] | null;
	sampleSets?: SampleSet[] | null;
}

export interface RocResponseLookUpModelArraySuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: RocResponseLookUpModel[] | null;
}

/** @format int32 */
export enum RocWarningType {
	None = 0,
	ShortNa = 1,
	SampleSetSize = 2,
	AqsaMentioned = 4,
	NotStarted = 8,
	PeerReviewRequired = 16,
	AttestationsOfScanCompliance = 32,
	QuarterlyScanVulnerabilities = 64,
	LastQuarterlyScan = 128,
	BusinessOverview = 256,
	AdditionalServices = 512,
	UseOfSubcontractors = 1024,
	RemoteTestingActivity = 2048,
	MissingChannelResponse = 4096,
	SegmentationUsed = 8192,
	SegmentationNotUsed = 16384,
	ProductsAndSolutions = 32768,
	SamplingIsUsed = 65536,
	SamplingNotUsed = 131072,
	StandardizedProcessesControls = 262144,
	RequirementStateNotSelected = 524288,
	RequirementDescriptionEmpty = 1048576,
	DateRangeError = 2097152,
	DateRangeIncomplete = 4194304,
}

export interface RoleToUserModel {
	/** @minLength 1 */
	userName: string;
	role: Roles;
}

/** @format int32 */
export enum Roles {
	GlobalAdministrator = 1,
	MasterAdministrator = 2,
	ClientAdministrator = 4,
	ProjectManager = 8,
	Qsa = 16,
	AssociateQsa = 32,
	TechnicalEditor = 64,
	Client = 128,
	Contact = 256,
	Auditor = 512,
	ExecutiveClientRepresentative = 1024,
}

export interface RootItemsModel {
	root?: RocItem;
	items?: RocItem[] | null;
	channels?: Channel[] | null;
	reportPath?: Breadcrumb[] | null;
	attachments?: AttachmentBaseModel[] | null;
	interviews?: InterviewModel[] | null;
	workflows?: Workflow[] | null;
	users?: ReportUser[] | null;
	contacts?: ReportContact[] | null;
	hints?: HelpMessage[] | null;
	filteredItemIds?: string[] | null;
	next?: RocNextModel;
}

export interface RootItemsModelSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: RootItemsModel;
}

export interface S3FileInfo {
	fileName?: string | null;
	/** @format int64 */
	size?: number;
	isInGlacier?: boolean;
}

export interface S3FileInfoArraySuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: S3FileInfo[] | null;
}

export interface SAQEntry {
	basedOn: Saq;
	notApplicableReason?: string | null;
	notTestedReason?: string | null;
	customSaqId?: string | null;
}

export interface SampleSet {
	id?: string | null;
	description?: string | null;
	type?: RocDataType;
	/** @minLength 1 */
	projectId: string;
	referenceNumber?: string | null;
	referenceItems?: NumberingSearch[] | null;
	items?: SampleSetItem[] | null;
	allItems?: string | null;
	selectionMethod?: string | null;
	/** @format int32 */
	totalSampled?: number;
	/** @format int32 */
	totalPopulation?: number;
	rocWarnings?: RocWarningType;
	trackingSheets?: MediaFile[] | null;
}

export interface SampleSetItem {
	item?: string | null;
	makeModelVersionRelease?: string | null;
	/** @format int32 */
	totalSampled?: number;
	/** @format int32 */
	totalPopulation?: number;
}

/** @format int32 */
export enum Saq {
	All = 0,
	A = 1,
	Aep = 2,
	B = 4,
	Bip = 8,
	C = 16,
	Cvt = 32,
	P2Pe = 64,
}

/** @format int32 */
export enum SearchEntities {
	Workflows = 1,
	Attachments = 2,
	ReportTextBlocks = 4,
	ReportTextFields = 8,
	ReportEvidenceLocations = 16,
}

export interface SearchResults {
	reportItems?: RocItem[] | null;
	workflows?: Workflow[] | null;
	attachments?: AttachmentBaseModel[] | null;
	query?: string | null;
	responseTimeMs?: string | null;
	chapters?: NumberingSearch[] | null;
	projects?: ProjectModel[] | null;
}

export interface SearchResultsSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: SearchResults;
}

export interface SetUserEnabledModel {
	/** @minLength 1 */
	userId: string;
	isEnabled: boolean;
}

export interface ShortCompanyInfo {
	companyId?: string | null;
	companyName?: string | null;
}

export interface ShortTimeZoneInfo {
	id?: string | null;
	offset?: string | null;
}

export interface ShortUserInfo {
	/** @minLength 1 */
	firstName: string;
	/** @minLength 1 */
	lastName: string;
	responsibilityArea?: ResponsibilityArea;
	customCredentials?: string | null;
	mentorName?: string | null;
	organization?: string | null;
	phoneNumber?: string | null;
	id?: string | null;
	userName?: string | null;
}

export interface ShortUserInfoArraySuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: ShortUserInfo[] | null;
}

export interface Snippet {
	id?: string | null;
	projectId?: string | null;
	itemId?: string | null;
	/** @minLength 1 */
	text: string;
	keywords?: string | null;
}

export interface SnippetListSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: Snippet[] | null;
}

export interface SourceControlTemplate {
	id?: string | null;
	name?: string | null;
	version?: ReportType;
	folderName?: string | null;
	fileName?: string | null;
	copyableGroups?: ReportGroup;
	differentClientCloneExcludedGroups?: ReportGroup;
	modelTypes?: ModelType;
	saqSections?: string[] | null;
	subTemplates?: SubTemplate[] | null;
	channelTypeTitles?: Record<string, string | null>;
	channelSubTypeTitles?: Record<string, string | null>;
	availableChannels?: Channel[] | null;
	isSingleFile?: boolean;
}

export interface SourceControlTemplateArraySuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: SourceControlTemplate[] | null;
}

export interface SourceControlTemplateSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: SourceControlTemplate;
}

export interface StatusReport {
	projectId?: string | null;
	/** @format int32 */
	completeAtStart?: number;
	/** @format int32 */
	completeAtFinish?: number;
	/** @format int32 */
	itemsCompletedAtStart?: number;
	/** @format int32 */
	itemsCompletedAtFinish?: number;
	/** @format int32 */
	itemsChanged?: number;
	/** @format int32 */
	requestsSent?: number;
	/** @format int32 */
	responsesReceived?: number;
	/** @format int32 */
	numberEvidencesUploaded?: number;
	/** @format int32 */
	interviewsCreated?: number;
	/** @format int32 */
	interviewsOccured?: number;
}

export interface StatusReportSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: StatusReport;
}

export interface StringObjectKeyValuePair {
	key?: string | null;
	value?: any;
}

export interface StringS3FileInfoArrayDictionarySuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: Record<string, S3FileInfo[] | null>;
}

export interface StringStringKeyValuePair {
	key?: string | null;
	value?: string | null;
}

export interface StringSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: string | null;
}

export interface StyleInformation {
	inlineStyles?: StringObjectKeyValuePair[] | null;
	classNames?: string[] | null;
	attributes?: Record<string, any>;
}

export interface SubTemplate {
	modelType?: AssessmentDocumentType;
	exportType?: DownloadFileType;
	id?: string | null;
	fileName?: string | null;
	isMain?: boolean;
}

export interface SuccessResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
}

/** @format int32 */
export enum SummaryOfAssessmentFindingsState {
	InPlace = 0,
	InPlaceW_CCW = 1,
	NA = 2,
	NotTested = 3,
	NotInPlace = 4,
}

export interface SummarySaq {
	summaryId?: string | null;
	status?: SummarySaqStatus;
}

/** @format int32 */
export enum SummarySaqStatus {
	Applicable = 0,
	NotTested = 1,
}

export interface SupportAttachment {
	/** @format int32 */
	id: number;
	/** @minLength 1 */
	name: string;
	/** @minLength 1 */
	link: string;
	/** @minLength 1 */
	checkSum: string;
	/** @format date-time */
	createdDate: string;
	fileSystemType: CentralFileSystemType;
	/** @format int32 */
	supportMessageId: number;
	message?: SupportMessage;
}

export interface SupportClientAttachment {
	/** @format int32 */
	id?: number;
	name?: string | null;
}

export interface SupportClientEvent {
	userId?: string | null;
	message?: SupportClientMessage;
	readMessageIds?: number[] | null;
	unreadMessageIds?: number[] | null;
	noMessages?: boolean;
}

export interface SupportClientMessage {
	/** @format int32 */
	id?: number;
	userId?: string | null;
	userName?: string | null;
	text?: string | null;
	/** @format date-time */
	createdDate?: string;
	/** @format date-time */
	readDate?: string | null;
	attachments?: SupportClientAttachment[] | null;
}

export interface SupportMessage {
	/** @format int32 */
	id: number;
	/** @minLength 1 */
	text: string;
	userId?: string | null;
	userName?: string | null;
	userEmail?: string | null;
	/** @format date-time */
	createdDate: string;
	/** @format date-time */
	readDate?: string | null;
	attachments?: SupportAttachment[] | null;
	/** @format int32 */
	supportTicketId?: number;
	ticket?: SupportTicket;
}

export interface SupportMessageListSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: SupportMessage[] | null;
}

export interface SupportMessageReadModel {
	ids?: number[] | null;
}

export interface SupportTicket {
	/** @format int32 */
	id: number;
	url?: string | null;
	/** @format date-time */
	createdDate: string;
	/** @format date-time */
	modifiedDate: string;
	status: SupportTicketStatus;
	impersonationToken?: string | null;
	messages?: SupportMessage[] | null;
	/** @format int32 */
	serverId?: number;
	server?: LicensedServer;
	lastMessage?: SupportMessage;
}

/** @format int32 */
export enum SupportTicketStatus {
	Open = 0,
	Pending = 1,
	Solved = 2,
}

export interface TextBlockRocItem {
	/** @format int32 */
	level?: number;
	parentsIds?: string[] | null;
	editorParentIds?: string[] | null;
	projectId?: string | null;
	itemId?: string | null;
	parentKey?: string | null;
	id?: RocItemId;
	/** @format int32 */
	order?: number;
	/** @format date-time */
	updateTime?: string;
	modifiedBy?: EntryAuthor;
	isDeleted?: boolean;
	isPermanentDeletionRequested?: boolean;
	untracked?: boolean;
	version?: ReportType;
	nodeType?: NodeType;
	group?: ReportGroup;
	standardHints?: HelpMessage[] | null;
	summaryId?: string | null;
	nonComplianceReasons?: NonComplianceReason[] | null;
	nonCompliant?: boolean;
	notApplicableReasons?: ReasonBase[] | null;
	notApplicable?: boolean;
	parentProgressItemId?: string | null;
	ignoreProgress?: boolean;
	forceProgress?: boolean;
	status?: ItemStatus;
	rocWarnings?: RocWarningType;
	customWarnings?: RocWarningType;
	hiddenWarnings?: RocWarningType;
	modelType?: ModelType;
	/** @deprecated */
	hasAutoEvent?: boolean;
	subType?: string | null;
	readPermissionMask?: Roles;
	writePermissionMask?: Roles;
	variableName?: string | null;
	autoComplete?: ReportAutoComplete;
	viewStyleInformation?: StyleInformation;
	style?: StyleInformation;
	contentsInfo?: RocContentsInfo;
	useDifferentValuesPerPaymentChannels?: boolean | null;
	isHiddenInReport?: boolean;
	responsibilityArea?: ResponsibilityArea;
	linkedItemId?: string | null;
	saq?: Saq;
	isHintsEnabled?: boolean;
	isGuidanceEnabled?: boolean;
	isLookUpDisabled?: boolean;
	isRequiredForEvidences?: boolean;
	isAutoGenerated?: boolean;
	isAutoModified?: boolean;
	/** @format date-time */
	lastAutofillDate?: string | null;
	needsWorkflowOnFill?: boolean;
	itemDescription?: string | null;
	interviewId?: string | null;
	/** @format int32 */
	requirementNumber?: number;
	numberingOrderBy?: string | null;
	excelAttributes?: ExcelAttributes;
	appliedScopeInfo?: AppliedScopeInfo;
	type?: RocItemType;
	text?: string | null;
	textLocalized?: Record<string, string | null>;
	textTemplate?: string | null;
	textTemplateLocalized?: Record<string, string | null>;
	format?: string | null;
}

export interface TextBlockRocItemArraySuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: TextBlockRocItem[] | null;
}

export interface TextChangeModel {
	authorName?: string | null;
	/** @format date-time */
	changeDateTime?: string;
	textDelta?: string | null;
}

export interface TextHistoryModel {
	currentText?: string | null;
	textChanges?: TextChangeModel[] | null;
}

export interface TextHistoryModelSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: TextHistoryModel;
}

export interface ThreatRiskConditionDetail {
	description?: string | null;
	isPreset?: boolean;
	/** @format int32 */
	rating?: number;
	/** @format int32 */
	priority?: number;
}

/** @format int32 */
export enum TokenStatus {
	Active = 0,
	Completed = 1,
	Disabled = 2,
	Expired = 3,
	Failed = 4,
}

export interface TokenSuccessResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	token?: string | null;
}

/** @format int32 */
export enum TokenType {
	Invitation = 0,
	Impersonation = 1,
}

export interface UserClientModel {
	/**
	 * @minLength 1
	 * @maxLength 36
	 */
	userId: string;
	/**
	 * @minLength 1
	 * @maxLength 36
	 */
	clientId: string;
	responsibilityArea?: ResponsibilityArea;
	isPrimary?: boolean;
}

export interface UserCompanyModel {
	userId?: string | null;
	companyId?: string | null;
}

export interface UserInfo {
	/** @minLength 1 */
	firstName: string;
	/** @minLength 1 */
	lastName: string;
	responsibilityArea?: ResponsibilityArea;
	customCredentials?: string | null;
	mentorName?: string | null;
	organization?: string | null;
	id?: string | null;
	userName?: string | null;
	clientName?: string | null;
	/** @format date-time */
	creationDate?: string;
	roles?: Roles[] | null;
	active?: boolean;
	emailConfirmed?: boolean;
	company?: ShortCompanyInfo[] | null;
	email?: string | null;
	phoneNumber?: string | null;
	userClient?: UserClientModel;
	/** @format date-time */
	lockoutEnd?: string | null;
	twoFactorEnabled?: boolean;
}

export interface UserInfoArraySuccessResultPagingResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: UserInfo[] | null;
	/** @format int32 */
	pagesCount?: number;
}

export interface UserInfoSuccessResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	id?: string | null;
	userName?: string | null;
	isEmailConfirmed?: boolean;
	hasPassword?: boolean;
	email?: string | null;
	firstName?: string | null;
	lastName?: string | null;
	roles?: Roles[] | null;
	clientResponsibility?: ResponsibilityArea;
	clientId?: string | null;
	isContact?: boolean;
	isFromRequest?: boolean;
	isImpersonated?: boolean;
	isAdmin?: boolean;
	isPolicyAccepted?: boolean;
	license?: Licensing;
	twoFactorEnabled?: boolean;
	workflowStatusMatrix?: Record<string, Record<string, ItemStatus>>;
	globalOptions?: GlobalOptions;
	companySupportEmail?: string | null;
	hotKeysMapJson?: string | null;
}

export interface UserMessage {
	/** @minLength 1 */
	id: string;
	/**
	 * @minLength 1
	 * @maxLength 36
	 */
	userId: string;
	/** @minLength 1 */
	code: string;
	/** @minLength 1 */
	message: string;
	/** @format date-time */
	creationDate: string;
	viewed?: boolean;
}

export interface UserMessagesSuccessResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	/** @format int32 */
	pagesCount?: number;
	userMessages?: UserMessage[] | null;
}

export interface UserProfile {
	/** @minLength 1 */
	firstName: string;
	/** @minLength 1 */
	lastName: string;
	responsibilityArea?: ResponsibilityArea;
	customCredentials?: string | null;
	mentorName?: string | null;
	organization?: string | null;
	emailNotificationsEnabled?: boolean;
	emailNotificationsAsDigest?: boolean;
	phoneNumber?: string | null;
	timeZone?: string | null;
	isAutoTimeZone?: boolean;
	availableTimeZones?: ShortTimeZoneInfo[] | null;
	hotKeysMap?: string | null;
}

export interface UserProfileSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: UserProfile;
}

export interface UserProjectModel {
	userId?: string | null;
	projectId?: string | null;
	role?: Roles;
	projectSubRoles?: ProjectSubRoles;
	info?: string | null;
}

/** @format int32 */
export enum UserType {
	User = 0,
	Contact = 1,
	Email = 2,
}

export interface VersionMigrationRequest {
	newReportType?: ReportType;
	projectId?: string | null;
}

export interface ViewTokensModel {
	senders?: ShortUserInfo[] | null;
	tokens?: CompanyToken[] | null;
}

export interface ViewTokensModelSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: ViewTokensModel;
}

export interface Workflow {
	/**
	 * @minLength 1
	 * @maxLength 36
	 */
	id: string;
	/**
	 * @minLength 1
	 * @maxLength 36
	 */
	projectId: string;
	/** @maxLength 36 */
	itemId?: string | null;
	/** @maxLength 36 */
	paymentChannelId?: string | null;
	/** @maxLength 36 */
	replyToWorkflowId?: string | null;
	untracked?: boolean;
	/** @format date-time */
	createdDate?: string;
	/** @format date-time */
	modifiedDate?: string;
	createdBy?: EntryAuthor;
	/** @maxLength 36 */
	linkedDataId?: string | null;
	linkedDataType?: Resource;
	message?: string | null;
	systemMessage?: string | null;
	status?: ItemStatus;
	forced?: boolean;
	rocDataType?: RocDataType;
	notApplicable?: boolean;
	resource?: Resource;
	access?: Access;
	rocDataInfo?: RocDataInfo[] | null;
	attachments?: AttachmentLink[] | null;
	visibleToRoles?: Roles;
	request?: Request;
	isAuto?: boolean;
	isAutofillLog?: boolean;
	isHidden?: boolean;
	isWorkPaper?: boolean;
	/** @format int32 */
	currentProjectProgress?: number;
	auditAction?: AuditAction;
}

export interface WorkflowInputModel {
	message?: string | null;
	visibleToCustomers?: boolean;
	/** @minLength 1 */
	projectId: string;
	/** @minLength 1 */
	itemId: string;
	isBulk?: boolean;
	isWorkPaper?: boolean;
	undraftedMessage?: string | null;
	request?: RequestInputModel;
	replyToWorkflowId?: string | null;
	status?: ItemStatus;
	bulkStatus?: ItemStatus;
	attachments?: NewAttachmentFile[] | null;
}

export interface WorkflowListSuccessResultResponse {
	success?: boolean;
	errors?: Record<string, string[] | null>;
	error?: string | null;
	optionalMessage?: string | null;
	data?: Workflow[] | null;
}

/** @format int32 */
export enum WorkflowRole {
	PeerReviewer = 1,
	Qsa = 2,
	Customer = 4,
	QA = 8,
}

/** @format int32 */
export enum XLAlignmentHorizontalValues {
	Center = 0,
	CenterContinuous = 1,
	Distributed = 2,
	Fill = 3,
	General = 4,
	Justify = 5,
	Left = 6,
	Right = 7,
}

/** @format int32 */
export enum XLAlignmentVerticalValues {
	Bottom = 0,
	Center = 1,
	Distributed = 2,
	Justify = 3,
	Top = 4,
}

export interface AccountLoginImpersonatedCreateParams {
	token?: string;
	auditToken?: string;
}

export interface AccountSendCodeViaEmailCreateParams {
	login?: string;
}

export interface AccountConfirmEmailUpdateParams {
	token?: string;
}

export interface AccountLockoutEndUpdateParams {
	token?: string;
}

export interface AccountEditProfileSettingsUpdateParams {
	/** @format int32 */
	clientTimeZoneOffset?: number;
}

export interface AccountEnableTwoFactorAuthenticationUpdateParams {
	code?: string;
}

export interface AccountForgotPasswordCreateParams {
	email: string;
}

export interface AccountEditUserSetLockoutEndCreateParams {
	userId?: string;
	/** @format date-time */
	lockoutDate?: string;
}

export interface AccountEditUserInfoUpdateParams {
	userId?: string;
}

export interface AccountViewUsersListToInviteListParams {
	UserName?: string;
	FirstName?: string;
	LastName?: string;
	CompanyId?: string;
	ClientId?: string;
	Role?: string;
	IsEnabled?: boolean;
	/** @format int32 */
	Page?: number;
	/** @format int32 */
	PageSize?: number;
	dataOrderIsAscending?: boolean;
	dataOrderPropertyName?: string;
	projectId?: string;
	clientId?: string;
}

export interface AccountGetUserMessagesListParams {
	/** @format int32 */
	Page?: number;
	/** @format int32 */
	PageSize?: number;
	dataOrderIsAscending?: boolean;
	dataOrderPropertyName?: string;
}

export interface AccountMarkMessageAsReadUpdateParams {
	messageId?: string;
}

export interface AccountViewUsersListParams {
	UserName?: string;
	FirstName?: string;
	LastName?: string;
	CompanyId?: string;
	ClientId?: string;
	Role?: string;
	IsEnabled?: boolean;
	/** @format int32 */
	Page?: number;
	/** @format int32 */
	PageSize?: number;
	dataOrderIsAscending?: boolean;
	dataOrderPropertyName?: string;
}

export interface AccountGetUserInfoListParams {
	/** @format int32 */
	clientTimeZoneOffset?: number;
}

export interface AccountUnsubscribeCreateParams {
	token?: string;
}

export interface AccountGetTestEmailsListParams {
	email?: string;
}

export interface ArrayItemsImportCreatePayload {
	ProjectId?: string;
	ArrayId?: string;
	Files?: FileInputModel[];
}

export interface AttachmentsListDetailParams {
	type?: RocDataType;
	rocModelId: string;
}

export interface AuditLogViewAuditLogListParams {
	projectId?: string;
	ClientResponsibilities?: ResponsibilityArea;
	SaqEnabled?: boolean;
	InterviewArea?: ResponsibilityArea;
	EvidenceArea?: ResponsibilityArea;
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateUntil?: string;
	AuthorId?: string;
	ItemStatus?: ItemStatus;
	Summary?: SummaryOfAssessmentFindingsState;
	RocWarning?: RocWarningType;
	IsWithUnexpiredRequests?: boolean;
	IsWithRequestsTargetedAtMe?: boolean;
	RequestTargetedAt?: string;
	IsWithoutInput?: boolean;
	IsWithCCW?: boolean;
	IsWithIncompleteCCW?: boolean;
	IsWithCustomApproach?: boolean;
	IgnoreSystemWorkflows?: boolean;
	IsItemFilterActive?: boolean;
	IsOnlySaqFilterActive?: boolean;
	IsAnyItemFilterActive?: boolean;
	IsWorkflowFilterActive?: boolean;
	/** @format int32 */
	Page?: number;
	/** @format int32 */
	PageSize?: number;
	dataOrderIsAscending?: boolean;
	dataOrderPropertyName?: string;
}

export interface ClientDeleteClientDeleteParams {
	id?: string;
}

export interface ClientGetMyClientsListParams {
	companyId?: string;
	withProjects?: boolean;
	withActiveProjects?: boolean;
	withUnallocatedResponsibilities?: boolean;
}

export interface ClientDownloadTemplateListParams {
	companyId?: string;
}

export interface ClientImportCreatePayload {
	CompanyId?: string;
	Files?: FileInputModel[];
}

export interface CompanyDeleteCompanyDeleteParams {
	id?: string;
}

export interface CompanyViewCompaniesOfUserListParams {
	userId?: string;
}

export interface CompanyTokenListListParams {
	companyId?: string;
	tokenType?: TokenType;
}

export interface CompanyTokenDeactivateUpdateParams {
	companyId?: string;
	tokenId?: string;
}

export interface CompanyTokenResendCreateParams {
	companyId?: string;
	tokenId?: string;
}

export interface ContactViewListParams {
	projectId?: string;
	contactId?: string;
}

export interface ContactUpdateContactUpdateParams {
	projectId?: string;
}

export interface ContactDeleteContactDeleteParams {
	id?: string;
}

export interface CustomSaqListListParams {
	templateVersion?: ReportType;
	clientId?: string;
}

export interface CustomSaqViewSummariesListParams {
	templateVersion?: ReportType;
}

export interface CustomSaqAddOrRemoveSummaryCreateParams {
	summaryId?: string;
	status?: SummarySaqStatus;
	isAll?: boolean;
	id: string;
}

export interface EmailSettingsSendTestEmailCreateParams {
	email?: string;
}

export interface EmailSettingsListEmailsListParams {
	/** @format int64 */
	before?: number;
}

export interface EvidencesCreateCreatePayload {
	ProjectId: string;
	RocItemId?: string;
	PaymentChannelId?: string;
	NewAttachments?: NewAttachmentFile[];
	ExistingAttachments?: Record<string, string | null>;
}

export interface EvidencesUpdateAttachmentCreatePayload {
	Id: string;
	Files?: NewAttachmentFile[];
	WebUrl?: string;
	Description?: string;
	EvidenceLocation?: string;
	/** @format date-time */
	ActualDateTime?: string;
	Version?: string;
	/** @format binary */
	File?: File;
}

export interface EvidencesEvidenceDownloadDetailParams {
	rocItemId?: string;
	projectId: string;
	attachmentId: string;
}

export interface EvidencesArchiveQueryCreateParams {
	/** @default true */
	approvedOnly?: boolean;
	projectId: string;
}

export interface EvidencesDownloadArchiveListParams {
	token?: string;
}

export interface HelpDeskIssueCreatePayload {
	Text: string;
	IsImpersonationAllowed: boolean;
	Files?: FileInputModel[];
}

export interface InterviewUpdateInterviewUpdateParams {
	id?: string;
}

export interface InterviewDeleteInterviewDeleteParams {
	id?: string;
}

export interface InterviewExportListParams {
	id?: string;
	downloadToken?: string;
}

export interface InterviewGetInterviewListParams {
	interviewId?: string;
}

export interface InterviewViewInterviewsListParams {
	/** @format date-time */
	startDate?: string;
	/** @format date-time */
	endDate?: string;
}

export interface InterviewGetProjectInterviewsListParams {
	projectId?: string;
	/** @format date-time */
	startDate?: string;
	/** @format date-time */
	endDate?: string;
}

export interface InterviewImportCreatePayload {
	ProjectId: string;
	RocItemId?: string;
	Files?: FileInputModel[];
}

export interface MpaViewMpaRequestsListParams {
	ActionType?: string;
	Decision?: string;
	/** @format date-time */
	StartDate?: string;
	/** @format date-time */
	EndDate?: string;
	/** @format int32 */
	Page?: number;
	/** @format int32 */
	PageSize?: number;
	dataOrderIsAscending?: boolean;
	dataOrderPropertyName?: string;
}

export interface MpaApproveMpaRequestCreateParams {
	id?: string;
	approved?: boolean;
}

export interface MpaSetActionEnabledCreateParams {
	eventId?: string;
	isEnabled?: boolean;
}

export interface OptionsSetCreatePayload {
	BrandFiles?: NewBrandFile[];
	/** @default true */
	TelemetryEnabled?: boolean;
	WorkflowQAEnabled?: boolean;
	/** @default "en-US" */
	Culture?: string;
	'Brand.Title'?: string;
	'Brand.Description'?: string;
	'Brand.LogoUrl'?: string;
	'Brand.EmailLogoPngUrl'?: string;
	'Brand.HorizontalLogoUrl'?: string;
	'Brand.BackgroundUrl'?: string;
	'Brand.BackgroundSizePx'?: string;
	'Brand.ManifestUrl'?: string;
	'Brand.FaviconUrl'?: string;
	'Brand.ReportHeaderUrl'?: string;
	'Brand.ReportCoverPageUrl'?: string;
	'Brand.Colors.MainLight'?: string;
	'Brand.Colors.MainDark'?: string;
	'Brand.Colors.Secondary'?: string;
	'Brand.Colors.Success'?: string;
	'Brand.Colors.Highlight'?: string;
	'Brand.Colors.Warning'?: string;
	'Brand.Colors.Error'?: string;
	'Brand.FilesConfig'?: Record<string, BrandAllowedMimeTypes>;
	UseRequirementNumberInEvidenceRef?: boolean;
	/** @default true */
	UseIncompleteRocWatermark?: boolean;
	UseDescriptionForEvidenceRef?: boolean;
	UseLocationForEvidenceRef?: boolean;
	AllowResponsesWithoutChannel?: boolean;
	ProjectTypeNaMessageTemplate?: string;
}

export interface PlatinumTemplateUpdateCreateParams {
	reverse?: boolean;
}

export interface ProjectRestartFailedReportCreationPartCreateParams {
	projectId?: string;
}

export interface ProjectRecalculateProgressCreateParams {
	projectId: string;
}

export interface ProjectRunScriptsCreateParams {
	projectId: string;
}

export interface ProjectMapScopeCreateParams {
	projectId: string;
	scopeProjectId: string;
}

export interface ProjectDeleteProjectDeleteParams {
	id?: string;
}

export interface ProjectViewProjectsListParams {
	IsActive?: boolean;
	ClientId?: string;
	CreatedByMe?: boolean;
	/** @format date-time */
	CreatedAfter?: string;
	Version?: ReportType;
}

export interface ProjectViewTemplateProjectsListParams {
	templateVersion?: ReportType;
}

export interface ProjectViewUsersOfProjectListParams {
	projectId?: string;
	includeContacts?: boolean;
	area?: ResponsibilityArea;
}

export interface ProjectAssignUserToRequirementUpdateParams {
	/** @format int32 */
	requirement?: number;
	isAssignment?: boolean;
	projectId: string;
	userId: string;
}

export interface RemoteCommandExecuteCreateParams {
	id?: string;
}

export interface ReportViewStatusReportListParams {
	ProjectId: string;
	/** @format date-time */
	StartDate?: string;
	/** @format date-time */
	EndDate?: string;
}

export interface ReportDownloadStatusReportListParams {
	ProjectId: string;
	/** @format date-time */
	StartDate?: string;
	/** @format date-time */
	EndDate?: string;
}

export interface ResponsibilityAreaGetItemsDetailParams {
	responsibilities?: ResponsibilityArea;
	rocModelId: string;
}

export interface RiskToolExportXlsxListParams {
	id?: string;
}

export interface RocItemControllerNewReportViewDetailParams {
	rocItemId?: string;
	ClientResponsibilities?: ResponsibilityArea;
	SaqEnabled?: boolean;
	InterviewArea?: ResponsibilityArea;
	EvidenceArea?: ResponsibilityArea;
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateUntil?: string;
	AuthorId?: string;
	ItemStatus?: ItemStatus;
	Summary?: SummaryOfAssessmentFindingsState;
	RocWarning?: RocWarningType;
	IsWithUnexpiredRequests?: boolean;
	IsWithRequestsTargetedAtMe?: boolean;
	RequestTargetedAt?: string;
	IsWithoutInput?: boolean;
	IsWithCCW?: boolean;
	IsWithIncompleteCCW?: boolean;
	IsWithCustomApproach?: boolean;
	IgnoreSystemWorkflows?: boolean;
	IsItemFilterActive?: boolean;
	IsOnlySaqFilterActive?: boolean;
	IsAnyItemFilterActive?: boolean;
	IsWorkflowFilterActive?: boolean;
	/** @format int32 */
	Page?: number;
	/** @format int32 */
	PageSize?: number;
	dataOrderIsAscending?: boolean;
	dataOrderPropertyName?: string;
	rocModelId: string;
}

export interface RocItemControllerNewViewReportNextRequestListParams {
	projectId?: string;
	currentItemId?: string;
}

export interface RocItemControllerNewViewReportNextPageListParams {
	projectId: string;
	chapterId: string;
}

export interface RocItemControllerNewViewReportNextItemCreateParams {
	projectId: string;
	currentChapterId?: string;
	currentItemId?: string;
	boundaryChapterId?: string;
}

export interface RocItemControllerNewSearchItemDetailParams {
	numbering: string;
	rocModelId: string;
}

export interface RocItemControllerNewTextHistoryDetailParams {
	rocItemId?: string;
	paymentChannelId?: string;
	rocModelId: string;
}

export interface RocItemEditorTemplateViewDetailParams {
	rocItemId?: string;
	rocModelId: string;
}

export interface RocLookUpUseCreateParams {
	channelId?: string;
	currentProjectId: string;
	usedProjectId: string;
	itemId: string;
	dataId: string;
}

export interface RocModelControllerNewTemplateDetailsDetailParams {
	type: string;
}

export interface RocModelControllerNewSearchListParams {
	projectId?: string;
	/** @minLength 3 */
	query: string;
	entities: SearchEntities;
	caseSensitive?: boolean;
}

export interface SampleSetsUploadTrackingSheetCreatePayload {
	ProjectId?: string;
	SampleSetId?: string;
	Attachment?: NewAttachmentFile[];
}

export interface SnippetsListListParams {
	projectId?: string;
	itemId?: string;
}

export interface SnippetsDeleteDeleteParams {
	projectId?: string;
	id: string;
}

export interface UploadFileCreateParams {
	projectId?: string;
	itemId?: string;
	fileName?: string;
}

export interface DownloadFileListParams {
	projectId?: string;
	itemId?: string;
	fileName?: string;
}

export interface DeleteFileCreateParams {
	projectId?: string;
	itemId?: string;
	fileName?: string;
}

export interface IsExistsListParams {
	projectId?: string;
	itemId?: string;
	fileName?: string;
}

export interface NotifyArchiveReadyCreateParams {
	userId?: string;
	token?: string;
	error?: string;
}

export interface WorkflowCreateCreatePayload {
	ProjectId: string;
	ItemId: string;
	IsBulk?: boolean;
	IsWorkPaper?: boolean;
	UndraftedMessage?: string;
	'Request.UserIds'?: string[];
	'Request.ContactIds'?: string[];
	'Request.Emails'?: RequestEmailModel[];
	/** @format date-time */
	'Request.ValidUntil'?: string;
	ReplyToWorkflowId?: string;
	Status?: ItemStatus;
	BulkStatus?: ItemStatus;
	Attachments?: NewAttachmentFile[];
	Message?: string;
	VisibleToCustomers?: boolean;
}

export interface WorkflowTempLoginListParams {
	token?: string;
}

export interface WorkflowGetUserInfoForRegistrationListParams {
	token?: string;
}

export interface WorkflowApproveFileCreateParams {
	isApproved?: boolean;
	projectId: string;
	workflowId: string;
	attachmentId: string;
}

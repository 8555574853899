import React from 'react';
import { css, cx } from '@emotion/css/macro';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import { Delete, Save } from '@mui/icons-material';
import { BasicForm, PureCheckboxField, PureTextField } from '../formaggio';
import { Heading, IconButton } from '../core/components';
import { darkgrey, error } from '../ui/Core/stylesheets/colors';
import { useReportAccess } from '../auth/hooks';
import { useAPI, useToggle } from '../core/hooks';
import { useReportContext } from './context/ReportContext';
import { useItemContext } from './context/ItemContext';
import { RemediationPriority } from '../core/types/api';
import { CustomSaqStatus } from '../customSaq/enums';
import { hasFlag } from '../core/helpers';
import { DEFAULT_RFT_NA_MESSAGE } from '../customSaq/constants';

const priorityClass = css`
	display: flex;
	align-items: center;
`;

const priorityLabelClass = css`
	margin-right: 16px !important;
	display: block;
`;

function Priority({ values, changeReason }: any) {
	return (
		<div className={priorityClass}>
			<Typography variant="body1" className={priorityLabelClass}>
				Priority
			</Typography>
			<RadioGroup
				style={{ display: 'flex', flexDirection: 'row' }}
				value={String(values.priority || `${RemediationPriority.Low}`)}
				onChange={changeReason}
				name="priority"
			>
				<FormControlLabel
					value={`${RemediationPriority.Low}`}
					control={<Radio size="small" />}
					label="Low"
				/>
				<FormControlLabel
					value={`${RemediationPriority.Medium}`}
					control={<Radio size="small" />}
					label="Medium"
				/>
				<FormControlLabel
					value={`${RemediationPriority.High}`}
					control={<Radio size="small" />}
					label="High"
				/>
			</RadioGroup>
		</div>
	);
}

const reasonClass = css`
	margin-bottom: 16px;
`;

const recommendationClass = css`
	margin-bottom: 8px;
`;

function ReasonReadOnly({
	channelName,
	reason,
	resolutionRecommendation,
	priority,
	nonCompliantVariant,
}: any) {
	return (
		<div className={recommendationClass}>
			{channelName && <>{channelName} - </>}
			Reason:
			<br />
			{reason}
			{nonCompliantVariant && (
				<>
					{resolutionRecommendation && (
						<>
							<br />
							<small>Resolution Recommendation: {resolutionRecommendation}</small>
						</>
					)}
					{priority && (
						<>
							<br />
							<small>Priority: {priority}</small>
						</>
					)}
				</>
			)}
		</div>
	);
}

const channelReasonClass = css`
	padding: 8px 16px;
	border: 1px solid ${darkgrey};
	border-radius: 5px;
	margin-bottom: 8px;
`;

const channelNameClass = css`
	margin: 4px 0 16px 0;
	font-weight: 600;
`;

type ReasonProps = {
	paymentChannelName?: string;
	values: any;
	changeReasons: any;
	idx: number;
	canEdit?: boolean;
	nonCompliantVariant?: boolean;
};

function Reason({
	paymentChannelName,
	values = {},
	changeReasons,
	idx,
	canEdit,
	nonCompliantVariant,
}: ReasonProps) {
	const changeReason = React.useCallback((e) => changeReasons(idx, e), [changeReasons, idx]);

	if (!canEdit && values.reason)
		return <ReasonReadOnly channelName={paymentChannelName} {...values} />;

	return (
		<div className={cx(paymentChannelName && channelReasonClass)}>
			{paymentChannelName && <div className={channelNameClass}>{paymentChannelName}</div>}
			<PureTextField
				fullWidth
				multiline
				variant="outlined"
				name="reason"
				label="Reason"
				value={values.reason || ''}
				onChange={changeReason}
				className={reasonClass}
				size="small"
				noGutter
			/>
			{nonCompliantVariant && (
				<>
					<PureTextField
						fullWidth
						multiline
						variant="outlined"
						name="resolutionRecommendation"
						label="Resolution Recommendation"
						value={values.resolutionRecommendation || ''}
						onChange={changeReason}
						size="small"
						noGutter
						className={recommendationClass}
					/>
					<Priority values={values} changeReason={changeReason} />
				</>
			)}
		</div>
	);
}

const reasonHeaderClass = css`
	color: firebrick !important;
	margin-bottom: 16px !important;
`;

const rootClass = css`
	padding: 8px 16px;
	border-radius: 5px;
	margin: 16px 0;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	min-width: 500px;
	border: 1px solid rgb(128, 0, 0);
`;

const nonCompliantBackground = css`
	background-color: rgba(192, 0, 0, 0.1);
`;
const notApplicableBackground = css`
	background-color: rgba(0, 0, 0, 0.05);
`;

const controlsClass = css`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	&:last-child {
		margin-bottom: 0;
	}
`;

const controlButtonsClass = css`
	display: flex;
	flex-direction: column;
	flex: 0 1 auto;
`;

type NonComplianceReasonsProps = {
	itemId?: string;
	reasons?: any[];
	setReasons: React.Dispatch<React.SetStateAction<any[] | undefined>>;
	nonCompliantVariant?: boolean; //Resolution and priority fields for this variant
	canEdit?: boolean;
	methodName: string;
	applicableClicked?: boolean;
	setApplicableClicked?: (arg: boolean) => void;
};

function ItemReasons({
	itemId,
	reasons = [],
	setReasons,
	nonCompliantVariant,
	canEdit,
	methodName,
	applicableClicked,
	setApplicableClicked,
}: NonComplianceReasonsProps) {
	const { paymentChannels } = useReportAccess();
	const { projectId, refresh } = useReportContext();
	const { itemId: iid, lists, saq: itemSaq, itemProperties } = useItemContext();

	const naApplicableChannels = React.useMemo(
		() =>
			paymentChannels.filter(
				(c) =>
					lists.applicableChannelIds.indexOf(c.id) >= 0 ||
					reasons.filter((r) => r.paymentChannelId === c.id).length > 0,
			),
		[paymentChannels, lists.applicableChannelIds, reasons],
	);

	//preserves original NA input in case of accidental ALL Check click
	const [origReason, setOrigReason] = React.useState(reasons);
	//Check if any default saq response was overrode
	const saqOverride = React.useMemo(
		() =>
			itemSaq != null &&
			naApplicableChannels.some(
				(c) =>
					(c.saq?.customSaqId != null &&
						lists.allChannelStates.some(
							(cs) => cs.cid === c.id && cs.status !== CustomSaqStatus.Applicable,
						)) ||
					(c.saq?.basedOn != null && !hasFlag(itemSaq, c.saq?.basedOn)),
			),
		[itemSaq, naApplicableChannels, lists.allChannelStates],
	);

	const displayAllCheck = React.useMemo(
		() =>
			((!saqOverride && !itemProperties.isSummary) || nonCompliantVariant) &&
			canEdit &&
			naApplicableChannels.length > 1,
		[naApplicableChannels, nonCompliantVariant, canEdit, itemProperties.isSummary, saqOverride],
	);

	const defaultReasons = React.useMemo(() => {
		if (!saqOverride) return [{ reason: 'N/A' }];
		return naApplicableChannels.map((c) => ({
			reason: c.saq != null ? c.saq.notApplicableReason ?? DEFAULT_RFT_NA_MESSAGE : 'N/A',
			paymentChannelId: c.id,
		}));
	}, [naApplicableChannels, saqOverride]);

	const { isOn: isSaved, toggleOff: savedOff, toggleOn: savedOn } = useToggle(true);
	const changeReasons = React.useCallback(
		(idx: number, e: any) => {
			savedOff();
			setReasons((prevState) => {
				const newState: any = [...(prevState || [])];
				newState[idx][e.target.name] = e.target.value;
				return newState;
			});
		},
		[savedOff, setReasons],
	);

	const changeMode = React.useCallback(
		(e) => {
			setOrigReason(reasons);
			if (e.target.checked) {
				setReasons(origReason[0]?.paymentChannelId ? [{}] : origReason);
			} else {
				setReasons(
					origReason[0]?.paymentChannelId
						? origReason
						: naApplicableChannels.map(({ id }) => ({ paymentChannelId: id })),
				);
			}
		},
		[setReasons, naApplicableChannels, origReason, reasons],
	);

	const render = React.useMemo(() => {
		const defaultChannelName = displayAllCheck ? '' : naApplicableChannels[0]?.name;
		return {
			hasAllPaymentChannels: reasons?.some(({ paymentChannelId }) => !paymentChannelId),
			list: reasons?.map((reason, idx) => (
				<Reason
					key={idx}
					idx={idx}
					paymentChannelName={
						reason.paymentChannelId
							? paymentChannels.filter(({ id }) => id === reason.paymentChannelId)[0]?.name
							: defaultChannelName
					}
					values={reason}
					changeReasons={changeReasons}
					canEdit={canEdit}
					nonCompliantVariant={nonCompliantVariant}
				/>
			)),
		};
	}, [
		naApplicableChannels,
		changeReasons,
		displayAllCheck,
		canEdit,
		reasons,
		nonCompliantVariant,
		paymentChannels,
	]);

	const fetchProps: any = {
		query: `RocItemControllerNew/${methodName}/${projectId}/${itemId || iid}`,
		method: 'POST',
	};

	const { initialFetch } = useAPI({ props: { ...fetchProps, params: {}, onSuccess: refresh } });
	const deleteReasons = React.useCallback(() => {
		initialFetch();
		setReasons(undefined);
	}, [initialFetch, setReasons]);

	React.useEffect(() => {
		//override in place on SAQ channel, include channel to NA form (All Channels unchecked case)
		if (reasons.length > 1 && reasons.length < naApplicableChannels.length) {
			const newReasons = [...reasons];

			naApplicableChannels.forEach((c) => {
				if (newReasons.filter((r) => r.paymentChannelId === c.id).length < 1) {
					newReasons.push({
						reason:
							c.saq != null && !nonCompliantVariant
								? c.saq.notApplicableReason ?? DEFAULT_RFT_NA_MESSAGE
								: '',
						paymentChannelId: c.id,
					});
					savedOff();
				}
			});

			setReasons(newReasons);
		}
		//override in place on SAQ channel, include channel to NA form (All Channels checked case)
		if (
			reasons.length === 1 &&
			saqOverride &&
			((naApplicableChannels.length === 1 && reasons[0].paymentChannelId == null) || //case with all channels NA as RFT and override is required
				naApplicableChannels.length > 1) &&
			!nonCompliantVariant
		) {
			const newReasons = [];

			naApplicableChannels.forEach((c) => {
				newReasons.push({
					reason:
						c.saq?.customSaqId != null ||
						(c.saq?.basedOn != null && !hasFlag(itemSaq, c.saq?.basedOn))
							? c.saq.notApplicableReason ?? DEFAULT_RFT_NA_MESSAGE
							: reasons[0].reason,
					paymentChannelId: c.id,
				});
			});

			setReasons(newReasons);
			savedOff();
		}
	}, [
		reasons,
		setReasons,
		naApplicableChannels,
		nonCompliantVariant,
		saqOverride,
		savedOff,
		itemSaq,
	]);

	React.useEffect(() => {
		if (applicableClicked) {
			setReasons(defaultReasons);
			initialFetch({ params: { reasons: defaultReasons } });
			setApplicableClicked(false);
		}
	}, [applicableClicked, defaultReasons, initialFetch, setApplicableClicked, setReasons]);

	return (
		<BasicForm
			fetchConfig={{
				...fetchProps,
				onSuccess: () => {
					refresh();
					savedOn();
				},
			}}
			className={cx(
				rootClass,
				nonCompliantVariant ? nonCompliantBackground : notApplicableBackground,
			)}
			additional={{ reasons }}
		>
			<div className={controlsClass}>
				{displayAllCheck && (
					<PureCheckboxField
						label="All Channels"
						checked={render.hasAllPaymentChannels}
						onChange={changeMode}
					/>
				)}
				<Heading className={reasonHeaderClass}>
					{nonCompliantVariant ? 'Non-compliant' : 'N/A'}
				</Heading>
				{render.list}
			</div>
			{canEdit && (
				<div className={controlButtonsClass}>
					<IconButton color="primary" type="submit" disabled={isSaved}>
						<Save />
					</IconButton>
					<IconButton onClick={deleteReasons}>
						<Delete htmlColor={error} />
					</IconButton>
				</div>
			)}
		</BasicForm>
	);
}

export default function ItemReasonsWrapper(props: NonComplianceReasonsProps) {
	const { paymentChannels } = useReportAccess();

	if (!paymentChannels) return null;

	return <ItemReasons {...props} />;
}

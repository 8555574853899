import React from 'react';
import './modules/ui/Core/stylesheets/index.css';
import 'draft-js/dist/Draft.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { createRoot } from 'react-dom/client';
import store from './store/configureStore';
import App from './App';
import { checkAuth } from './modules/auth/AuthReducers';
import ApolloClientProvider from './store/ApolloClientProvider';
import theme from './modules/ui/Core/stylesheets/material';
import { unregister } from './registerServiceWorker';
import { HubContextProvider } from './modules/hubs/hooks';
import { ConfirmationContextProvider } from './modules/confirmation/context';
import { logUncaught } from './modules/core/helpers/loggerHelper';

store.dispatch(checkAuth());

createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<BrowserRouter>
					<ApolloClientProvider>
						<HubContextProvider>
							<ConfirmationContextProvider>
								<App />
							</ConfirmationContextProvider>
						</HubContextProvider>
					</ApolloClientProvider>
				</BrowserRouter>
			</Provider>
		</ThemeProvider>
	</React.StrictMode>,
);

window.addEventListener('error', logUncaught, { capture: true, passive: true });

//registerServiceWorker();
unregister();
